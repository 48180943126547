import React from "react";

const Impressum = (props) => {
  return (
    <>
      {props.language === 1 ? (
        <section className="legal">
          <div>
            <h1>Impressum</h1>
            <h2>Gesetzliche Anbieterkennung:</h2>
            <p>
              Ingrid Fuerbass
              <br />
              German: USt-IdNr.: DE124031164
              <br />
              Oberntorwall 21
              <br />
              33602 Bielefeld
              <br />
              Deutschland
              <br />
              Telefon: +491719350723
              <br />
              E-Mail: info@fashionmonks.com
            </p>
          </div>
          <div>
            <h2>Alternative Streitbeilegung:</h2>
            <p>
              Die Europäische Kommission stellt eine Plattform für die
              außergerichtliche Online-Streitbeilegung (OS-Plattform) bereit,
              aufrufbar unter{" "}
              <a href="https://ec.europa.eu/odr">https://ec.europa.eu/odr.</a>
            </p>
          </div>
        </section>
      ) : (
        <section className="legal">
          <div>
            <h1>Imprint</h1>
            <p>
              Ingrid Fuerbass
              <br />
              VAT No.: DE124031164
              <br />
              Oberntorwall 21
              <br />
              33602 Bielefeld
              <br />
              Deutschland
              <br />
              Telefon: +491719350723
              <br />
              E-Mail: info@fashionmonks.com
            </p>
          </div>
          <div>
            <h2>Dispute resolution:</h2>
            <p>
              For the out-of-court settlement of consumer disputes, the European
              Union provides an online platform("OS Platform") at the address{" "}
              <a href="https://ec.europa.eu/odr">https://ec.europa.eu/odr.</a>
            </p>
          </div>
        </section>
      )}
    </>
  );
};

export default Impressum;
