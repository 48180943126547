import React, { useState } from 'react';
import { Link } from 'react-scroll';
import { NavLink } from 'react-router-dom';
import uk from './assets/img/UKFlag.png';
import germany from './assets/img/GermanyFlag.png';
// import home from './images/home.png'
// import Information from './images/information.png'
// import Showroom from './images/showroom.png'
// import Creator from './images/creator.png'
// import homeGer from './images/homeGer.png'
// import InformationGer from './images/information.png'
// import ShowroomGer from './images/showroomGer.png'
// import CreatorGer from './images/creator.png'

import './Navbar.css';

const Navbar = (props) => {
  const [click, setClick] = useState(false);

  const closeMenu = () => setClick(false);

  const imageClick = (item) => {
    props.setLanguage(item);
  };

  return (
    <div className="header">
      <nav className="navbar">
        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
          <li className="nav-item">
            {props.language === 0 ? (
              <Link
                to="hero"
                spy={true}
                smooth={true}
                offset={-80}
                duration={500}
                onClick={closeMenu}
              >
                <NavLink to={'/'} className="navlink">
                  <h1 className="menuText">Home</h1>
                </NavLink>
                {/* <img className='navImage2' src={home} alt='Home' /> */}
              </Link>
            ) : (
              <Link
                to="hero"
                spy={true}
                smooth={true}
                offset={-80}
                duration={500}
                onClick={closeMenu}
              >
                <NavLink to={'/'} className="navlink">
                  <h1 className="menuText">Home</h1>
                </NavLink>
                {/* <img className='navImage2' src={homeGer} alt='Home' /> */}
              </Link>
            )}
          </li>
          <li className="nav-item">
            {props.language === 0 ? (
              <Link
                to="about"
                spy={true}
                smooth={true}
                offset={-80}
                duration={500}
                onClick={closeMenu}
              >
                <NavLink to={'/'} className="navlink">
                  <h1 className="menuText">Information</h1>
                </NavLink>
                {/* <img className='navImage' src={Information} alt='Information' /> */}
              </Link>
            ) : (
              <Link
                to="about"
                spy={true}
                smooth={true}
                offset={-80}
                duration={500}
                onClick={closeMenu}
              >
                <NavLink to={'/'} className="navlink">
                  <h1 className="menuText">Information</h1>
                </NavLink>
                {/* <img className='navImage' src={InformationGer} alt='Information' /> */}
              </Link>
            )}
          </li>
          <li className="nav-item">
            {props.language === 0 ? (
              <Link
                to="testimonials"
                spy={true}
                smooth={true}
                offset={-80}
                duration={500}
                onClick={closeMenu}
              >
                <NavLink to={'/'} className="navlink">
                  <h1 className="menuText">Design</h1>
                </NavLink>
                {/* <img className='navImage' src={Creator} alt='Creator' /> */}
              </Link>
            ) : (
              <Link
                to="testimonials"
                spy={true}
                smooth={true}
                offset={-80}
                duration={500}
                onClick={closeMenu}
              >
                <NavLink to={'/'} className="navlink">
                  <h1 className="menuText">Design</h1>
                </NavLink>
                {/* <img className='navImage' src={CreatorGer} alt='Creator' /> */}
              </Link>
            )}
          </li>
          <li className="nav-item">
            {props.language === 0 ? (
              <Link
                to="demo"
                spy={true}
                smooth={true}
                offset={-80}
                duration={500}
                onClick={closeMenu}
              >
                <NavLink to={'/'} className="navlink">
                  <h1 className="menuText">Showroom</h1>
                </NavLink>
                {/* <img className='navImage2' src={Showroom} alt='Showroom' /> */}
              </Link>
            ) : (
              <Link
                to="demo"
                spy={true}
                smooth={true}
                offset={-80}
                duration={500}
                onClick={closeMenu}
              >
                <NavLink to={'/'} className="navlink">
                  <h1 className="menuText">Showroom</h1>
                </NavLink>
                {/* <img className='navImage2' src={ShowroomGer} alt='Showroom' /> */}
              </Link>
            )}
          </li>
          <li className="nav-item-contact">
            {props.language === 0 ? (
              <Link
                to="contact"
                spy={true}
                smooth={true}
                offset={-80}
                duration={500}
                onClick={closeMenu}
              >
                <NavLink to={'/'} className="navlink">
                  <h1 className="menuText">Contact</h1>
                </NavLink>
                {/* <img className='navImage2' src={Showroom} alt='Showroom' /> */}
              </Link>
            ) : (
              <Link
                to="contact"
                spy={true}
                smooth={true}
                offset={-80}
                duration={500}
                onClick={closeMenu}
              >
                <NavLink to={'/'} className="navlink">
                  <h1 className="menuText">Contact</h1>
                </NavLink>
                {/* <img className='navImage2' src={ShowroomGer} alt='Showroom' /> */}
              </Link>
            )}
          </li>
        </ul>
        <ul className="nav navbar-nav navbar-right">
          <img
            className="flags"
            onClick={() => imageClick(0)}
            src={uk}
            alt="English"
          />
          <img
            className="flags"
            onClick={() => imageClick(1)}
            src={germany}
            alt="German"
          />
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
