import React from "react";

export const Widerrufsbelehrung = (props) => {
  return (
    <>
      {props.language === 1 ? (
        <section className="legal">
          <h4>Informationen zum Verbraucher-Widerrufsrecht</h4>
          <h4>Widerrufsbelehrung für postalisch gelieferte Waren</h4>
          <h4>Widerrufsrecht</h4>
          <p>
            Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen
            diesen Vertrag zu widerrufen. Die Widerrufsfrist beträgt vierzehn
            Tage ab dem Tag, an dem Sie oder ein von Ihnen benannter Dritter,
            der nicht der Beförderer ist, die Waren in Besitz genommen haben
            bzw. hat.
          </p>
          <p>
            Um Ihr Widerrufsrecht auszuüben, müssen Sie uns (Ingrid Fuerbass,
            Oberntorwall 21, 33602 Bielefeld, Telefon +491719350723, E-Mail
            info@fashionmonks.com) mittels einer eindeutigen Erklärung (z.B. ein
            mit der Post versandter Brief oder eine E-Mail) über Ihren
            Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können
            dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch
            nicht vorgeschrieben ist.
          </p>
          <p>
            Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die
            Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der
            Widerrufsfrist absenden.
          </p>
          <h4>Folgen des Widerrufs</h4>
          <p>
            Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen,
            die wir von Ihnen erhalten haben, einschließlich der Lieferkosten
            (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass
            Sie eine andere Art der Lieferung als die von uns angebotene,
            günstigste Standardlieferung gewählt haben), unverzüglich und
            spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem
            die Mitteilung über Ihren Widerruf dieses Vertrags bei uns
            eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe
            Zahlungsmittel, das Sie bei der ursprünglichen Transaktion
            eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas
            anderes vereinbart; in keinem Fall werden Ihnen wegen dieser
            Rückzahlung Entgelte berechnet.
          </p>
          <p>
            Wir können die Rückzahlung verweigern, bis wir die Waren wieder
            zurückerhalten haben oder bis Sie den Nachweis erbracht haben, dass
            Sie die Waren zurückgesandt haben, je nachdem, welches der frühere
            Zeitpunkt ist.
          </p>
          <p>
            Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen
            vierzehn Tagen ab dem Tag, an dem Sie uns über den Widerruf dieses
            Vertrags unterrichten, an uns zurückzusenden oder zu übergeben. Die
            Frist ist gewahrt, wenn Sie die Waren vor Ablauf der Frist von
            vierzehn Tagen absenden. Sie tragen die unmittelbaren Kosten der
            Rücksendung der Waren. Sie müssen für einen etwaigen Wertverlust der
            Waren nur aufkommen, wenn dieser Wertverlust auf einen zur Prüfung
            der Beschaffenheit, Eigenschaften und Funktionsweise der Waren nicht
            notwendigen Umgang mit ihnen zurückzuführen ist.
          </p>
          <h4>Ausnahmen vom Widerrufsrecht</h4>
          <p>
            Das Widerrufsrecht besteht nicht bei Verträgen über die Lieferung
            von Waren, die nicht vorgefertigt sind und für deren Herstellung
            eine individuelle Auswahl oder Bestimmung durch den Verbraucher
            maßgeblich ist oder die eindeutig auf die persönlichen Bedürfnisse
            des Verbrauchers zugeschnitten sind.
          </p>
          <hr />
          <h4>Muster-Widerrufsformular</h4>
          <p>
            (Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte
            dieses Formular aus und senden Sie es zurück)
          </p>
          <p>
            — An: Ingrid Fuerbass, Oberntorwall 21, 33602 Bielefeld, E-Mail
            info@fashionmonks.com
          </p>
          <p>
            — Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*)
            abgeschlossenen Vertrag über den Kauf der folgenden Waren (*) / die
            Erbringung der folgenden Dienstleistung (*)
          </p>
          <p>— Bestellt am (*) / erhalten am (*)</p>
          <p>— Name des/der Verbraucher(s)</p>
          <p>— Anschrift des/der Verbraucher(s)</p>
          <p>
            — Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf
            Papier)
          </p>
          <p> — Datum (*) Unzutreffendes streichen.</p>
        </section>
      ) : (
        <section className="legal">
          <h4>Information on Consumers' Right of Withdrawal</h4>
          <h4>
            Information on the right of withdrawal for goods delivered by post
          </h4>
          <h4>Right of withdrawal</h4>
          <p>
            You have the right to withdraw from this contract within 14 days
            without giving any reason. The withdrawal period will expire after
            14 days from the day on which you acquire, or a third party other
            than the carrier and indicated by you acquires, physical possession
            of the goods.
          </p>
          <p>
            To exercise the right of withdrawal, you must inform us (Ingrid
            Fuerbass, Oberntorwall 21, 33602 Bielefeld, phone +491719350723,
            e-mail info@fashionmonks.com) of your decision to withdraw from this
            contract by an unequivocal statement (e.g. a letter sent by post or
            e-mail). You may use the attached model withdrawal form, but it is
            not obligatory.
          </p>
          <p>
            To meet the withdrawal deadline, it is sufficient for you to send
            your communication concerning your exercise of the right of
            withdrawal before the withdrawal period has expired.
          </p>
          <h4>Effects of withdrawal</h4>
          <p>
            If you withdraw from this contract, we shall reimburse to you all
            payments received from you, including the costs of delivery (with
            the exception of the supplementary costs resulting from your choice
            of a type of delivery other than the least expensive type of
            standard delivery offered by us), without undue delay and in any
            event not later than 14 days from the day on which we are informed
            about your decision to withdraw from this contract. We will carry
            out such reimbursement using the same means of payment as you used
            for the initial transaction, unless you have expressly agreed
            otherwise; in any event, you will not incur any fees as a result of
            such reimbursement.
          </p>
          <p>
            We may withhold reimbursement until we have received the goods back
            or you have supplied evidence of having sent back the goods,
            whichever is the earliest.
          </p>
          <p>
            You shall send back the goods or hand them over to us without undue
            delay and in any event not later than 14 days from the day on which
            you communicate your withdrawal from this contract to us. The
            deadline is met if you send back the goods before the period of 14
            days has expired.
          </p>
          <h4>Exceptions from the right of withdrawal</h4>
          <p>
            The right of withdrawal shall not exist for contracts regarding the
            delivery of merchandise that is not prefabricated and is made
            according to the consumer's individual choice and specifications, or
            of merchandise that obviously is tailored to meet personal customer
            requirements.
          </p>
          <hr />
          <h4>Sample Withdrawal Form</h4>
          <p>
            (complete and return this form only if you wish to withdraw from the
            contract)
          </p>
          <p>
            — To: Ingrid Fuerbass, Oberntorwall 21, 33602 Bielefeld, e-mail
            info@fashionmonks.com
          </p>
          <p>
            — I/We (*) hereby give notice that I/We (*) withdraw from my/our (*)
            contract of sale of the following goods (*) / for the provision of
            the following service (*)
          </p>
          <p>— Ordered on (*) / received on (*)</p>
          <p>— Name of consumer(s)</p>
          <p>— Address of consumer(s)</p>
          <p>
            — Signature of consumer(s) (only if this form is notified on paper)
          </p>
          <p> — Date (*) Delete as appropriate.</p>
        </section>
      )}
    </>
  );
};
