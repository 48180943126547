import { Link } from 'react-router-dom';
import './ContactForm.css';
import { useForm, ValidationError } from '@formspree/react';
//import { motion } from 'framer-motion';

function ContactForm(props) {
  const [state, handleSubmit] = useForm('mqkrnerq');
  if (state.succeeded) {
    return <p>Thanks for joining!</p>;
  }
  return (
    <>
      {props.language === 0 ? (
        <form onSubmit={handleSubmit} class="contact-form" id="contact">
          <h2>Make Contact</h2>
          <div class="name-fields">
            <div class="name-field">
              <label for="nombre">Name</label>
              <input id="nombre" type="text" name="nombre" />
            </div>
            <div class="name-field">
              <label for="apellido">Surname</label>
              <input id="apellido" type="text" name="apellido" />
            </div>
          </div>
          <div class="full-width-field">
            <label for="email">Email Address</label>
            <input id="email" type="email" name="email" />
            <ValidationError
              prefix="Email"
              field="email"
              errors={state.errors}
            />
          </div>
          <div class="full-width-field">
            <label for="message">Message</label>
            <textarea id="message" name="message"></textarea>
            <ValidationError
              prefix="Message"
              field="message"
              errors={state.errors}
            />
          </div>

          <div class="accept-conditions">
            <input type="checkbox" id="acceptance" name="acceptance" />
            <label for="acceptance">
              <strong>Acceptance consent</strong>
            </label>
          </div>
          <p>
            I have read the{' '}
            <Link to={'datenschutzerklaerung'}>Datenschutzerklaerung </Link>{' '}
            declaration and agree to the processing of my data.
          </p>
          <button
            className="button-form"
            type="submit"
            disabled={state.submitting}
          >
            Submit
          </button>
        </form>
      ) : (
        <form onSubmit={handleSubmit} class="contact-form" id="contact">
          <h2>Kontakt</h2>
          <div class="name-fields">
            <div class="name-field">
              <label for="nombre">Vorname</label>
              <input id="nombre" type="text" name="nombre" />
            </div>
            <div class="name-field">
              <label for="apellido">Nachname</label>
              <input id="apellido" type="text" name="apellido" />
            </div>
          </div>
          <div class="full-width-field">
            <label for="email">Email Address</label>
            <input id="email" type="email" name="email" />
            <ValidationError
              prefix="Email"
              field="email"
              errors={state.errors}
            />
          </div>
          <div class="full-width-field">
            <label for="message">Nachricht</label>
            <textarea id="message" name="message"></textarea>
            <ValidationError
              prefix="Message"
              field="message"
              errors={state.errors}
            />
          </div>
          <div class="accept-conditions">
            <input type="checkbox" id="acceptance" name="acceptance" />
            <label for="acceptance">
              <strong>Acceptance zustimmung</strong>
            </label>
          </div>
          <p>
            Ich habe die{' '}
            <Link to={'datenschutzerklaerung'}>Datenschutzerklaerung </Link>{' '}
            gelesen und stimme der Verarbeitung meiner Daten zu.
          </p>
          <button
            className="button-form"
            type="submit"
            disabled={state.submitting}
          >
            Submit
          </button>
        </form>
      )}
    </>
  );

  /* const [state, handleSubmit] = useForm('mnqrdpjn');

  if (state.submitting) {
    return (
      <div className="">
        <div className="contactModal contactModalConfirmation">
          <div>
            <h2>Sending Message</h2>
            <p>
              Just a sec...
              <br />
              Also feel free to contact me via Linkedin:
            </p>
            <div className="linkedinLink">
              <a
                href="https://www.linkedin.com/in/emanuel-villalba-01b3a7106/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-linkedin"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (state.succeeded) {
    return (
      <div className="">
        <div className="contactModal contactModalConfirmation">
          <a href="#!" className="close" onClick={props.onClose}>
            <i className="fa fa-xmark"></i>
          </a>
          <div>
            <h2>Thanks for your contact!</h2>
            <p>
              Your message was sent!
              <br />
              Also feel free to contact me via Linkedin:
            </p>
            <div className="linkedinLink">
              <a
                href="https://www.linkedin.com/in/emanuel-villalba-01b3a7106/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-linkedin"></i>
              </a>
            </div>
          </div>

          <div className="confirmationButton">
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className="btn btn-filled"
              onClick={props.onClose}
            >
              OK
            </motion.button>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div div className="">
      <div className="contactModal">
        <a href="#!" className="close" onClick={props.onClose}>
          <i className="fa fa-xmark"></i>
        </a>

        <h2>Contact me</h2>

        <form id="contactForm" className="contactForm" onSubmit={handleSubmit}>
          <div className="row">
            <div className="inputField">
              <label htmlFor="email">Email Address</label>
              <input id="email" type="email" name="email" required />

              <ValidationError
                prefix="Email"
                field="email"
                errors={state.errors}
              />
            </div>
          </div>
          <div className="inputField">
            <textarea id="message" name="message" required />
            <ValidationError
              prefix="Message"
              field="message"
              errors={state.errors}
            />
          </div>

          <div className="action">
            <div className="linkedinLink">
              <a
                href="https://www.linkedin.com/in/emanuel-villalba-01b3a7106/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-linkedin"></i>
              </a>
            </div>
            <div className="sendLink">
             <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                type="submit"
                disabled={state.submitting}
                className="btn btn-filled"
              >
                Send Message
              </motion.button> 
            </div>
          </div>
        </form>
      </div>
    </div>
  ); */
}
export default ContactForm;
