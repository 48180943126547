import contact from './assets/img/letra.png';
import { Link } from 'react-scroll';
import './FloatingContactButton.css';

const FloatingContactButton = () => {
  return (
    <div className="floating-contact-button">
      <div className="contact-icon-wrapper">
        <Link to="contact" spy={true} smooth={true} offset={-80} duration={500}>
          <img src={contact} alt="Contact" className="contact-icon" />
        </Link>
      </div>
    </div>
  );
};

export default FloatingContactButton;
