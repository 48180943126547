const allProducts = [
  {
    id: 0,
    src: "/showroom/BohoChic/LINN.jpg",
    name: "LINN",
    description:
      "With the beautiful pastel colored  patchwork design and the fun loose balloon cut, every woman cuts a good figure, an eye catcher and must-have in the summertime.",
    material: "Material 100% cotton",
    size: "Onesize / Oversize",
    collection: "Oversize Dresses + Boho Chic",
  },
  {
    id: 1,
    name: "ISA-BELLA",
    src: "/showroom/BohoChic/ISA-BELLA.jpg",
    description:
      "The stylish beautiful print is a real eye catcher, and the wonderful silk material of this dress ensure fine elegance, and is very comfortable to wear on the skin. Bring color in to play for your big appearance.",
    material: "Material: 100% silk ",
    size: "Onesize / Oversize",
    collection: "Oversize Dresses + Boho Chic",
  },
  {
    id: 2,
    name: "YOKO",
    src: "/showroom/BohoChic/YOKO.jpg",
    description:
      "Extravagant and elegant chic-look in a light beautiful material. The model has a loose cut in a style of a kaftan. The sophisticated gathering in the waist aerea flatters the figure in an appealing way.",
    material: "Material: 60% Cotton 40% Polyester",
    size: "Onesize / Oversize",
    collection: "Oversize Dresses + Boho Chic",
  },
  {
    id: 3,
    name: "CHAKA",
    src: "/showroom/BohoChic/CHAKA.jpg",
    description:
      "The beautiful subtle tiger print gives this model in the slightly flowing viskose fabric an exotic touch, and the shaping gathering at the waist creates an eye-catcher.",
    material: "Material: 100% Viskose",
    size: "Onesize / Oversize",
    collection: "Oversize Dresses + Boho Chic",
  },
  {
    id: 4,
    name: "IMKE",
    src: "/showroom/BohoChic/IMKE.jpg",
    description:
      "The fresh green brings a good mood and positive energy, and the swinging cut excudes boho vibes. With this figure flattering dress the summer will be colorful and cheerful.",
    material: "Material: 100% cotton",
    size: "Onesize / Oversize",
    collection: "Oversize Dresses + Boho Chic",
  },
  {
    id: 5,
    name: "LIV",
    src: "/showroom/BohoChic/LIV.jpg",
    description:
      "Fancy patchwork design in beautiful pastel colors has a fresh and revitalizing effect, and the individual balloon cut gives the dress a casual note. There is no boredom.",
    material: "Material: 100% cotton",
    size: "Onesize / Oversize",
    collection: "Oversize Dresses + Boho Chic",
  },
  {
    id: 6,
    name: "YUNA",
    src: "/showroom/BohoChic/YUNA.jpg",
    description:
      "Relaxed silhouette, refined detail and feminine elegance design in a subtle black and white look. In the firmer material this model is waerable in all seasons.",
    material: "Material: 95% Polyester 5% Elastan",
    size: "Onesize / Oversize",
    collection: "Oversize Dresses + Boho Chic",
  },
  {
    id: 7,
    name: "GAGA",
    src: "/showroom/BohoChic/GAGA.jpg",
    description:
      "Chic cool balloon dress with a slightly body hugging cut. This outfit has a very special touch due to the colorful wonderful design and the shiny soft flowing material. What more do you need for a stylish appearance?.",
    material: "Material 100% polyester (satin-polyester, slightly stretchy)",
    size: "Onesize / Oversize",
    collection: "Oversize Dresses + Boho Chic",
  },
  {
    id: 8,
    name: "GAGA JACKET",
    src: "/showroom/BohoChic/GAGA JACKIE.jpg",
    description:
      "This slightly oversized long jacket with the loose fit, provides a casual touch and is a nice addition to the balloon dress. Worn solo extravagant, and multifaced combined with many plain colored items of clothing.",
    material: "Material 100% polyester (satin-polyester, slightly stretchy)",
    size: "Onesize / Oversize",
    collection: "Oversize Dresses + Boho Chic",
  },
  {
    id: 9,
    name: "GAGA TOP",
    src: "/showroom/BohoChic/GAGA TOP.jpg",
    description: "Perfect GAGA COMBINATION",
    material: "Material 100% polyester (satin-polyester, slightly stretchy)",
    size: "Onesize / Oversize",
    collection: "Oversize Dresses + Boho Chic",
  },
  {
    id: 10,
    name: "NORAH",
    src: "/showroom/BohoChic/NORAH.jpg",
    description:
      "This beautiful model in A-shape looks elegant and glamourous due the shiny material and the feather boa trimmings and definiteley has party potential. The great fit flatters the figure in a beautiful way and ensures lively creativity.",
    material:
      "Material 100% Polyester \n trimmed with turkey feather boa black",
    size: "Onesize / Oversize",
    collection: "Oversize Dresses + Boho Chic",
  },
  {
    id: 11,
    name: "ZOE",
    src: "/showroom/BohoChic/ZOE.jpg",
    description:
      "Straight loose cut in contemporary fashion style, with knitted collar at the neck and sleeves. In the wonderful black grey tiger print and with a long matching scarf, the model is a cool outfit and very figure-friendly.",
    material: "Material 100% viscose \n Knitted collar 100% cotton",
    size: "Onesize / Oversize",
    collection: "Oversize Dresses + Boho Chic",
  },
  {
    id: 12,
    name: "ISA",
    src: "/showroom/In Love with Black & White/ISA.jpg",
    description:
      "A dream in white. \n With this swinging dress you are on course for summer, and casual as well as elegant you ensure a wow appearance. A playful mix the long scarf, tied here as a bow, gives that certain something.",
    material: "Material: 100% cotton",
    size: "Onesize / Oversize",
    collection: "In Love with Black & White",
  },
  {
    id: 13,
    name: "FRIDA",
    src: "/showroom/In Love with Black & White/FRIDA.jpg",
    description:
      "Modern, fresh, elegant and irresistible is this attractive black and withe patchwork dress. Simple but not plain, a womanly figure is also set in scene with this figure flattering outfit.",
    material: "Material: 100% cotton",
    size: "Onesize / Oversize",
    collection: "In Love with Black & White",
  },
  {
    id: 14,
    name: "CHER",
    src: "/showroom/In Love with Black & White/CHER.jpg",
    description:
      "Cool stylish tunic dress in black and with patchwork look. The refined straight cut stands for timelessness and elegance and flutters and conceals the figure. Simply Chic this modern simple elegance.",
    material: "Material: 100% cotton",
    size: "Onesize / Oversize",
    collection: "In Love with Black & White",
  },
  {
    id: 15,
    name: "CHER SET",
    src: "/showroom/In Love with Black & White/CHER SET.jpg",
    description:
      "An eye catcher. \n With the long scarf in black & white as a highlight, it spices up the outfit to something special.",
    material:
      "Material: dress 100% cotton \n Material: scarf 97% cotton 3% Elastan",
    size: "Onesize / Oversize",
    collection: "In Love with Black & White",
  },
  {
    id: 16,
    name: "CARLA",
    src: "/showroom/In Love with Black & White/CARLA.jpg",
    description:
      "Opposites attract! \n This casual jacket in a firmer material, with wide sleeves and a black & white charismatic stripe design, creates a varied look. In combination with a black or white piece of clothing, individual & cool.",
    material: "Material: jacket 97 % cotton 3% elastan",
    size: "Onesize / Oversize",
    collection: "In Love with Black & White",
  },
  {
    id: 17,
    name: "CARLA Long",
    src: "/showroom/In Love with Black & White/CARLA LANG.jpg",
    description: "Elegant modernity & charisma",
    material: "Material: 97 % Cotton 3% Elastan",
    size: "Onesize / Oversize",
    collection: "In Love with Black & White",
  },
  {
    id: 18,
    name: "CARLA SET COAT LONG",
    src: "/showroom/In Love with Black & White/CARLA SET COAT LONG.jpg",
    description:
      "In this figure-hugging variant, with the long scarf tied in the upper part, this model is almost a figure-shaping magic coat, as well as a trendy individual alternative, and recommanded for a rather tall woman.",
    material: "Material 97% cotton 3% elastan",
    size: "Onesize / Oversize",
    collection: "In Love with Black & White",
  },
  {
    id: 19,
    name: "ROMY",
    src: "/showroom/In Love with Black & White/ROMY.jpg",
    description:
      "The classic beauty. The swinging beautiful wide and comfortable cut, and the soft white cotton material convey an airy feel. There is Summer in! Absolutely figure-friendly the model can appeal to a wide range of modern women.",
    material: "Material: 100% cotton",
    size: "Onesize / Oversize",
    collection: "In Love with Black & White",
  },
  {
    id: 20,
    name: "CORA",
    src: "/showroom/In Love with Black & White/CORA.jpg",
    description:
      "Beautiful noble avant-garde light linen dress, in charismatic black & white patchwork look, this model is something special. Gently and casually flatters the figure in an appealing way and puts every woman in limelight. \n A dress to fall in love with.",
    material: "Material: 100% linen",
    size: "Onesize / Oversize",
    collection: "In Love with Black & White",
  },
  {
    id: 21,
    name: "CORA SET",
    src: "/showroom/In Love with Black & White/CORA SET.jpg",
    description:
      "Do you love the special? \n Stylish and extravagant the CORA model with black and white linen scarf in the same material, simply an eye-catcher.",
    material: "Material: scarf 100% linen \n Material dress 100% linen",
    size: "Onesize / Oversize",
    collection: "In Love with Black & White",
  },
  {
    id: 22,
    name: "CINDY",
    src: "/showroom/In Love with Black & White/CINDY.jpg",
    description:
      "Cool tunic dress, gently, elegant, casual and flattering in the cut. With the fine stripe structure in creamy white-black, and with the soft material, this dress is an outfit for all seasons.",
    material: "Material: 95% Polyester 5% Elastan",
    size: "Onesize / Oversize",
    collection: "In Love with Black & White",
  },
  {
    id: 23,
    name: "NANCY",
    src: "/showroom/In Love with Black & White/NANCY.jpg",
    description:
      "A modern outfit and a fresh look conveys this dress in a wide cut. The white cotton material gives the feeling of lightness and freshness, and the perfect fit flatteringly conceals problem aereas. Perfect for every age! That’s makes you want summer. ",
    material: "Material: 100% cotton",
    size: "Onesize / Oversize",
    collection: "In Love with Black & White",
  },
  {
    id: 24,
    name: "NANCY SET SCARF SHORT",
    src: "/showroom/In Love with Black & White/NANCY SET SCARF SHORT.jpg",
    description: "A fresh cheeky look.",
    material:
      "Material scarf 100 % cotton 3% elastan \n Material dress 100% cotton",
    size: "Onesize / Oversize",
    collection: "In Love with Black & White",
  },
  {
    id: 25,
    name: "NANCY SET SCARF LONG",
    src: "/showroom/In Love with Black & White/NANCY SET SCARF LONG.jpg",
    description: "An optical eye catcher. This model is open for any fun.",
    material:
      "Material scarf 100% Cotton 3% Elastan \n Material dress 100% cotton",
    size: "Onesize / Oversize",
    collection: "In Love with Black & White",
  },
  {
    id: 26,
    name: "NANCY SET WITH SCARF LONG BLACK",
    src: "/showroom/In Love with Black & White/NANCY SET with SCARF LONG BLACK.jpg",
    description: "",
    material: "Material: scarf cotton \n material dress cotton",
    size: "Onesize / Oversize",
    collection: "In Love with Black & White",
  },
  {
    id: 27,
    name: "UTA",
    src: "/showroom/In Love with Black & White/UTA.jpg",
    description:
      "Classic timeless figure-hugging cut, sensual and sexy, and the soft supple material make it very comfortable to wear.",
    material: "Material: 92% nylon 8% elastan",
    size: "Onesize / Oversize",
    collection: "In Love with Black & White",
  },
  {
    id: 28,
    name: "UTA SET with Top",
    src: "/showroom/In Love with Black & White/UTA SET WITH TOP.jpg",
    description:
      "A nice play with asymmetries turnes the figure-hugging UTA dress into a figure-shaping elegant outfit. Wunderfully concealing with the asymmetric top, this attractive outfit suits almost every woman in every age, and also waerable in many occassions.",
    material: "Material: 92% nylon 8% elastan",
    size: "Onesize / Oversize",
    collection: "In Love with Black & White",
  },
  {
    id: 29,
    name: "LENA",
    src: "/showroom/In Love with Black & White/LENA.jpg",
    description:
      "Asymmetric top with a loose light and flowing fit, ensures a chic and casual touch in combination with many items of clothing.",
    material: "Material: 92% nylon 8% elastan",
    size: "Onesize / Oversize",
    collection: "In Love with Black & White",
  },
  {
    id: 30,
    name: "ANN",
    src: "/showroom/In Love with Black & White/ANN.jpg",
    description:
      "Shirt Dress with a straight wide slightly light oversized cut, in a flowing silhouette with side slits, conveys sommery happiness.",
    material: "Material: 100% cotton",
    size: "Onesize / Oversize",
    collection: "In Love with Black & White",
  },
  {
    id: 31,
    name: "ANNA",
    src: "/showroom/In Love with Black & White/ANNA.jpg",
    description:
      "Shirt dress in black, with a straight wide light slightly oversized cut, in a flowing silhouette and with side slits, shows both, elegance and lightness.",
    material: "Material: 100% cotton",
    size: "Onesize / Oversize",
    collection: "In Love with Black & White",
  },
  {
    id: 32,
    name: "ANNA SET SCARF SHORT",
    src: "/showroom/In Love with Black & White/ANNA SET SCARF SHORT.jpg",
    description: "",
    material: "Scarf material 97% cotton 3% elastan \n Dress 100% cotton",
    size: "Onesize / Oversize",
    collection: "In Love with Black & White",
  },
  {
    id: 33,
    name: "CAMILLE SET",
    src: "/showroom/In Love with Black & White/CAMILLE SET.jpg",
    description:
      "this set, white maxidress, trendy, simple, sleeveless, in combination with this top in black & white, is stylish, individual, concealing and a cool outfit. Especially recommanded for a tall woman.",
    material: "Material: dress & top 100% cotton",
    size: "Onesize / Oversize",
    collection: "In Love with Black & White",
  },
  {
    id: 34,
    name: "RONJA",
    src: "/showroom/In Love with Black & White/RONJA.jpg",
    description:
      "This black cotton dress in a wide A-line cut, looks timelessly simple and elegant, but the effect is always different depending on the selection of accessoires.",
    material: "Material: 100% cotton",
    size: "Onesize / Oversize",
    collection: "In Love with Black & White",
  },
  {
    id: 35,
    name: "LOUISE",
    src: "/showroom/In Love with Black & White/LOUISE.jpg",
    description:
      "Chic, cool and slightly oversized , made of black linen, with a casual clear classic cut, modern, elegant and versatile.",
    material: "Material: 100% linen",
    size: "Onesize / Oversize",
    collection: "In Love with Black & White",
  },
  {
    id: 36,
    name: "LOUISE In combination with dress ANN",
    src: "/showroom/In Love with Black & White/LOUISE 2.jpg",
    description: "In combination with dress ANN",
    material: "Material: 100% linen",
    size: "Onesize / Oversize",
    collection: "In Love with Black & White",
  },
  {
    id: 37,
    name: "MANDY",
    src: "/showroom/Happy Hippie Hop/MANDY.jpg",
    description:
      "shines in delicate cheerful pastel colors and in a patchwork mix. The slightly fared cut with a creative swing, in the light cotton material, is figure-friendly and comfortable and lets you look casual and individual.",
    material: "Material: 100% cotton",
    size: "Onesize / Oversize",
    collection: "Happy Hippie Hop",
  },
  {
    id: 38,
    name: "ZURI",
    src: "/showroom/Happy Hippie Hop/ZURI.jpg",
    description:
      "Fresh cheeky balloon dress, slightly oversized with a subtle grey and white stripe structure, impresses with its simple beauty, slightly figure-shaping and uncomplicated.",
    material: "Material: 60% cotton 40% polyester",
    size: "Onesize / Oversize",
    collection: "Happy Hippie Hop",
  },
  {
    id: 39,
    name: "GINA",
    src: "/showroom/Happy Hippie Hop/GINA.jpg",
    description:
      "An endless summer dress, chic and revitalizing in a fresh green. This oversized Maxidress has a great fit and suitable for everyday use, or a nice event, this loose dress always works.",
    material: "Material: 100% cotton",
    size: "Onesize / Oversize",
    collection: "Happy Hippie Hop",
  },
  {
    id: 42,
    name: "ELISA",
    src: "/showroom/Happy Hippie Hop/ELISA.jpg",
    description:
      "with a hip look and the charismatic trendy design, is this slightly body-hugging Maxidress, made of soft material, wonderful to wear. Not boring at all, with this dress you go straight to the club.",
    material: "Material: 100% polyester",
    size: "Onesize / Oversize",
    collection: "Happy Hippie Hop",
  },
  {
    id: 43,
    name: "ELISA SET",
    src: "/showroom/Happy Hippie Hop/ELISASET.jpg",
    description:
      "This look with the asymmetrical Top has the right combination, and a womanly figure also gives this outfit a wow appearance.",
    material: "Material: 100% polyester",
    size: "Onesize / Oversize",
    collection: "Happy Hippie Hop",
  },
  {
    id: 44,
    name: "ELISA TOP",
    src: "/showroom/Happy Hippie Hop/ELISATOP.jpg",
    description: "There are no limits to the possible combinations.",
    material: "Material: 100% polyester",
    size: "Onesize / Oversize",
    collection: "Happy Hippie Hop",
  },
  {
    id: 45,
    name: "FENJA",
    src: "/showroom/Happy Hippie Hop/FENJA.jpg",
    description:
      "Happy, modern, fresh and cheeky with a light body-hugging cut, this dress made oft soft supple material makes it easy to love.",
    material: "Material: 100% polyester",
    size: "Onesize / Oversize",
    collection: "Happy Hippie Hop",
  },
  {
    id: 46,
    name: "FENJA SET",
    src: "/showroom/Happy Hippie Hop/FENJASET.jpg",
    description:
      "Loosely concealing with the asymmetrical Top a beautiful combination, perfect for every age and every occasion.",
    material: "Material: 100% polyester",
    size: "Onesize / Oversize",
    collection: "Happy Hippie Hop",
  },
  {
    id: 47,
    name: "FENJA TOP",
    src: "/showroom/Happy Hippie Hop/FENJATOP.jpg",
    description:
      "This Top ist a real combination miracle, looks good over a dress, jeans, leggins o trousers.",
    material: "Material: 100% polyester",
    size: "Onesize / Oversize",
    collection: "Happy Hippie Hop",
  },
  {
    id: 48,
    name: "ELLA",
    src: "/showroom/Happy Hippie Hop/ELLA.JPG",
    description:
      "Feminine and minimalist design set the tone for this tunic dress. \n This piece of clothing is elegant,stylish, figure-friendly with a modern chic.",
    material: "Material: 60% cotton 40% polyester",
    size: "Onesize / Oversize",
    collection: "Happy Hippie Hop",
  },
  {
    id: 49,
    name: "SUZIE",
    src: "/showroom/Happy Hippie Hop/SUZIE.jpg",
    description:
      "Breezy balloon dress gives a feeling of lightness and chic, a modern and comfortable outfit, and the advantageous figure-flattering cut may appeal to a wide range of modern women.",
    material: "Material: 60% cotton 40% polyester",
    size: "Onesize / Oversize",
    collection: "Happy Hippie Hop",
  },
  {
    id: 50,
    name: "MONA",
    src: "/showroom/Happy Hippie Hop/MONA.jpg",
    description:
      "Balloon dress in an elegant grey-green pinstripe look. Timelessly casual and comfortable this dress fits almost every woman.",
    material: "Material: 60% cotton 40% polyester",
    size: "Onesize / Oversize",
    collection: "Happy Hippie Hop",
  },
  {
    id: 51,
    name: "XENIA",
    src: "/showroom/Happy Hippie Hop/XENIA.jpg",
    description:
      "Chic tunic with a straight figure-hugging fit, classic and timeless beautiful, with modern simple elegance. Comfortable with a side zip for enough movement.",
    material: "Material: 60% cotton 40% polyester",
    size: "Onesize / Oversize",
    collection: "Happy Hippie Hop",
  },
  {
    id: 52,
    name: "PIXIE",
    src: "/showroom/Happy Hippie Hop/PIXIE.jpg",
    description:
      "Tunic dress with a nice silhouette in a patchwork mix looks interesting,  fresh and colorful. The dress sits loosely and figure-friendly.",
    material: "Material: 100% cotton",
    size: "Onesize / Oversize",
    collection: "Happy Hippie Hop",
  },
  {
    id: 53,
    name: "VICKY SET with JACKET",
    src: "/showroom/Happy Hippie Hop/VICKYSETWJACKET.jpg",
    description:
      "Pastel-colored maxidress with jacket, lends nonchalance as well as elegance, due to the flared wide fit problem zones are wonderfully concealed ,and apart from being casual, this model also provides an all-round feeling of well being even on cooler days.",
    material: "Material: 50% cotton 50% polyacryl",
    size: "Onesize / Oversize",
    collection: "Happy Hippie Hop",
  },
  {
    id: 54,
    name: "VICKY SET with TOP",
    src: "/showroom/Happy Hippie Hop/VICKY SETWTOP.jpg",
    description: "and the effect is different again.",
    material: "Material: 50% cotton 50% polyacryl",
    size: "Onesize / Oversize",
    collection: "Happy Hippie Hop",
  },
  {
    id: 55,
    name: "MIRJA",
    src: "/showroom/Happy Hippie Hop/MIRJA.jpg",
    description:
      "Colorful patchwork dress, light and lively it flatters the figure, and the beautiful mix of colors conveys a sunny-light heartedness.",
    material: "Material: 100% cotton",
    size: "Onesize / Oversize",
    collection: "Happy Hippie Hop",
  },
  {
    id: 56,
    name: "EVI",
    src: "/showroom/Happy Hippie Hop/EVI.jpg",
    description:
      "Fresh short summer dress with a gathering in the waist aerea.",
    material: "Material: 100% cotton",
    size: "Onesize / Oversize",
    collection: "Happy Hippie Hop",
  },
  {
    id: 57,
    name: "LOLA",
    src: "/showroom/Happy Hippie Hop/LOLA.jpg",
    description:
      "Elegant cool dress in tunic style and light oversized, impresses with a gathering knot detail, subtle strip design and the casual figure-fluttering cut in soft material.",
    material: "Material: 60% cotton 40% polyester",
    size: "Onesize / Oversize",
    collection: "Happy Hippie Hop",
  },
  {
    id: 58,
    name: "COCO",
    src: "/showroom/Happy Hippie Hop/COCO.jpg",
    description:
      "Extravagant cool tunic dress, made of pure silk, act with the cheeky flowing colorful design and the slightly oversized fit. \n Simply flirty and sophisticated. \n It's summertime !",
    material: "Material: 100% silk",
    size: "Onesize / Oversize",
    collection: "Happy Hippie Hop",
  },
  {
    id: 59,
    name: "STELLA",
    src: "/showroom/Happy Hippie Hop/STELLA.jpg",
    description:
      "Chic avant-garde jacket made of pure silk, light straight oversized cut provides a casual note, perfect complement with dress COCO, but also extravagant and multifaced when worn solo. \n Fresh & free is the motto!",
    material: "Material: 100% silk",
    size: "Onesize / Oversize",
    collection: "Happy Hippie Hop",
  },
  {
    id: 40,
    name: "KIM",
    src: "/showroom/Happy Hippie Hop/KIM.jpg",
    description:
      "Beautiful high quality soft silk, the classic style, the modern charismatic colorful design, the straight wide cut, and the scarf tied to a bow, make this dress an optical highlight.",
    material: "Material: 100% silk",
    size: "Onesize / Oversize",
    collection: "Happy Hippie Hop",
  },
  {
    id: 60,
    name: "AMY",
    src: "/showroom/Kaftans/AMY 1.jpg",
    description:
      "With the fine silky material with an elegant sheen, the chic design and the flowing loose cut that gently flatters the figure, this Kaftan fit many different body shapes. With cool elegance you have a stylish appearance with this beautiful outfit.",
    material: "Material: 100% polyester",
    size: "Onesize / Oversize",
    collection: "Kaftan Avant Garde Dresses",
  },
  {
    id: 61,
    name: "BEA",
    src: "/showroom/Kaftans/BEA 2.jpg",
    description:
      "With the fine silky material with an elegant sheen, the chic design and the flowing loose cut that gently flatters the figure, this Kaftan fit many different body shapes. With cool elegance you have a stylish appearance with this beautiful outfit.",
    material: "Material: 100% polyester (silk satin)",
    size: "Onesize / Oversize",
    collection: "Kaftan Avant Garde Dresses",
  },
  {
    id: 62,
    name: "CELINA",
    src: "/showroom/Kaftans/CELINA 3.jpg",
    description:
      "With the fine silky material with an elegant sheen, the chic design and the flowing loose cut that gently flatters the figure, this Kaftan fit many different body shapes. With cool elegance you have a stylish appearance with this beautiful outfit.",
    material: "Material: 100% polyester (silk satin)",
    size: "Onesize / Oversize",
    collection: "Kaftan Avant Garde Dresses",
  },
  {
    id: 63,
    name: "DANA",
    src: "/showroom/Kaftans/DANA 4.jpg",
    description:
      "With the fine silky material with an elegant sheen, the chic design and the flowing loose cut that gently flatters the figure, this Kaftan fit many different body shapes. With cool elegance you have a stylish appearance with this beautiful outfit.",
    material: "Material: 100% polyester (silk satin)",
    size: "Onesize / Oversize",
    collection: "Kaftan Avant Garde Dresses",
  },
  {
    id: 64,
    name: "EDDA",
    src: "/showroom/Kaftans/EDDA 5.jpg",
    description:
      "With the fine silky material with an elegant sheen, the chic design and the flowing loose cut that gently flatters the figure, this Kaftan fit many different body shapes. With cool elegance you have a stylish appearance with this beautiful outfit.",
    material: "Material: 100% polyester (silk satin)",
    size: "Onesize / Oversize",
    collection: "Kaftan Avant Garde Dresses",
  },
  {
    id: 65,
    name: "FREYA",
    src: "/showroom/Kaftans/FREYA 6.jpg",
    description:
      "With the fine silky material with an elegant sheen, the chic design and the flowing loose cut that gently flatters the figure, this Kaftan fit many different body shapes. With cool elegance you have a stylish appearance with this beautiful outfit.",
    material: "Material: 100% polyester (silk satin)",
    size: "Onesize / Oversize",
    collection: "Kaftan Avant Garde Dresses",
  },
  {
    id: 66,
    name: "LIA",
    src: "/showroom/Kaftans/LIA 7.jpg",
    description:
      "With the fine silky material with an elegant sheen, the chic design and the flowing loose cut that gently flatters the figure, this Kaftan fit many different body shapes. With cool elegance you have a stylish appearance with this beautiful outfit.",
    material: "Material: 100% polyester (silk satin)",
    size: "Onesize / Oversize",
    collection: "Kaftan Avant Garde Dresses",
  },
  {
    id: 67,
    name: "MILA",
    src: "/showroom/Kaftans/MILA 8.jpg",
    description:
      "With the fine silky material with an elegant sheen, the chic design and the flowing loose cut that gently flatters the figure, this Kaftan fit many different body shapes. With cool elegance you have a stylish appearance with this beautiful outfit.",
    material: "Material: 100% polyester (silk satin)",
    size: "Onesize / Oversize",
    collection: "Kaftan Avant Garde Dresses",
  },
  {
    id: 68,
    name: "NOVA",
    src: "/showroom/Kaftans/NOVA 9.jpg",
    description:
      "With the fine silky material with an elegant sheen, the chic design and the flowing loose cut that gently flatters the figure, this Kaftan fit many different body shapes. With cool elegance you have a stylish appearance with this beautiful outfit.",
    material: "Material: 100% polyester (silk satin)",
    size: "Onesize / Oversize",
    collection: "Kaftan Avant Garde Dresses",
  },
  {
    id: 69,
    name: "MARA",
    src: "/showroom/Kaftans/MARA 10.jpg",
    description:
      "With the fine silky material with an elegant sheen, the chic design and the flowing loose cut that gently flatters the figure, this Kaftan fit many different body shapes. With cool elegance you have a stylish appearance with this beautiful outfit.",
    material: "Material: 100% polyester (silk satin)",
    size: "Onesize / Oversize",
    collection: "Kaftan Avant Garde Dresses",
  },
  {
    id: 70,
    name: "PIA",
    src: "/showroom/Kaftans/PIA 11.jpg",
    description:
      "With the fine silky material with an elegant sheen, the chic design and the flowing loose cut that gently flatters the figure, this Kaftan fit many different body shapes. With cool elegance you have a stylish appearance with this beautiful outfit.",
    material: "Material: 100% polyester (silk satin)",
    size: "Onesize / Oversize",
    collection: "Kaftan Avant Garde Dresses",
  },
  {
    id: 71,
    name: "NIKA",
    src: "/showroom/Kaftans/NIKA 12.jpg",
    description:
      "With the fine silky material with an elegant sheen, the chic design and the flowing loose cut that gently flatters the figure, this Kaftan fit many different body shapes. With cool elegance you have a stylish appearance with this beautiful outfit.",
    material: "Material: 100% polyester (silk satin)",
    size: "Onesize / Oversize",
    collection: "Kaftan Avant Garde Dresses",
  },
  {
    id: 72,
    name: "MILOU",
    src: "/showroom/Kaftans/MILOU 13.jpg",
    description:
      "With the fine silky material with an elegant sheen, the chic design and the flowing loose cut that gently flatters the figure, this Kaftan fit many different body shapes. With cool elegance you have a stylish appearance with this beautiful outfit.",
    material: "Material: 100% polyester (silk satin)",
    size: "Onesize / Oversize",
    collection: "Kaftan Avant Garde Dresses",
  },
  {
    id: 73,
    name: "CIAIRE",
    src: "/showroom/Kaftans/CIAIRE 14.jpg",
    description:
      "With the fine silky material with an elegant sheen, the chic design and the flowing loose cut that gently flatters the figure, this Kaftan fit many different body shapes. With cool elegance you have a stylish appearance with this beautiful outfit.",
    material: "Material: 100% polyester (silk satin)",
    size: "Onesize / Oversize",
    collection: "Kaftan Avant Garde Dresses",
  },
  {
    id: 74,
    name: "MONA",
    src: "/showroom/Kaftans/MONA 15.jpg",
    description:
      "With the fine silky material with an elegant sheen, the chic design and the flowing loose cut that gently flatters the figure, this Kaftan fit many different body shapes. With cool elegance you have a stylish appearance with this beautiful outfit.",
    material: "Material: 100% polyester (silk satin)",
    size: "Onesize / Oversize",
    collection: "Kaftan Avant Garde Dresses",
  },
];

export default allProducts;
