import Hero from '../components/Hero';
import About from '../components/About';
import Testimonials from '../components/Testimonials';
import Demo from '../components/Demo';
import ContactForm from '../components/ContactForm';
import FloatingContactButton from '../components/FloatingContactButton';

const Home = ({ language }) => {
  return (
    <>
      <FloatingContactButton />
      <Hero language={language} />
      <About language={language} />
      <Testimonials language={language} />
      <Demo language={language} />
      <ContactForm language={language} />
    </>
  );
};

export default Home;
