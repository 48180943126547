import React from 'react';
import './About.css';

const About = (props) => {
  return (
    <div className="about" id="about">
      <div className="container">
        {props.language === 0 ? (
          <div className="text">
            <p>
              FASHIONMONKS creates fashion with an oversized look that wants to
              convince through style-creativity-individuality and quality.
            </p>
            <p>
              We manufacture cool figure-flattering fashionable clothes,
              suitable for the different body shapes of fashion-conscious women
              in every age group. An oversized look means clothing that is
              deliberately worn e few sizes too big, which is trendy & chic, and
              on top of this comfortable, or just concealing and
              figure-friendly, these models fit a large number of women, with
              the most diverse proportions, because designed for a loose fit.
              May our creations appeal to a wide range of women and emphasize
              their personality.
            </p>
            <p>The philosophy: Is allowed what pleases.</p>
            <p>FOR EVERY BODY</p>
          </div>
        ) : (
          <div className="text">
            <p>
              FASHIONMONKS kreiert Mode im Oversize-Look, die in puncto
              Stil-Kreativitaet-Individualitaet, sowie Qualität überzeugen
              möchte.
            </p>
            <p>
              Wir fertigen coole, modische, und der Figur schmeichelnde
              Kleidungsstücke, passend für die verschiedenen Koerperformen der
              modebewussten Frau in jeder Altersgruppe. Oversize-Look bedeutet
              Kleidung, die ganz bewusst eine paar Nummern zu gross getragen
              wird, das ist trendy & chic, und obendrein auch noch bequem, oder
              eben kaschierend und figurfreundlich, passen diese Modelle einer
              Vielzahl von Frauen mit den unterschiedlichsten Proportionen, da
              entworfen fuer eine lockere PassForm. Moegen unsere Kreationen ein
              breites Spektrum an Trägerinnen ansprechen, und ihre
              Persönlichkeit hervorheben.
            </p>
            <p>Die Philosophie lautet: Erlaubt ist was gefällt.</p>
            <p>FOR EVERY BODY</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default About;
