import React, { useState, useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Root from './layout/Root';
import Home from './Views/Home';
import Datenschutzerklaerung from './Views/Datenschutzerklaerung';
import Impressum from './Views/Impressum';
import Geschaftsbedingungen from './Views/Geschaftsbedingungen';
import { Widerrufsbelehrung } from './Views/Widerrufsbelehrung';

function App() {
  const [language, setLanguage] = useState(1);

  useEffect(() => {
    setLanguage(language);
  }, [language]);

  const router = createBrowserRouter([
    {
      path: '',
      element: <Root language={language} setLanguage={setLanguage} />,
      children: [
        {
          index: true,
          element: <Home language={language} />,
        },
        {
          path: '*',
          element: <Home language={language} />,
        },
        {
          path: 'datenschutzerklaerung',
          element: <Datenschutzerklaerung language={language} />,
        },
        {
          path: 'impressum',
          element: <Impressum language={language} />,
        },
        {
          path: 'geschaftsbedingungen',
          element: <Geschaftsbedingungen language={language} />,
        },
        {
          path: 'widerrufsbelehrung',
          element: <Widerrufsbelehrung language={language} />,
        },
      ],
    },
  ]);

  return (
    <>
      <RouterProvider router={router}></RouterProvider>
    </>
  );
}

export default App;
