export const Datenschutzerklaerung = (props) => {
  return (
    <>
      {props.language === 1 ? (
        <section className="legal">
          <h4>Datenschutzerklärung</h4>
          <p>
            Nachstehend informieren wir Sie über Art, Umfang und Zweck der
            Verarbeitung Ihrer personenbezogenen Daten bei der Nutzung unseres
            Online-Angebots auf „www.fashionmonks.com“. Personenbezogene Daten
            sind alle Informationen, die sich auf eine identifizierte oder
            identifizierbare natürliche Person beziehen.
          </p>
          <h5>1. Verantwortlicher</h5>
          <p>
            Verantwortlicher im Sinne der EU-Datenschutzgrundverordnung (DSGVO)
            ist diejenige natürliche oder juristische Person, die allein oder
            gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung
            von personenbezogenen Daten entscheidet. Für die von uns
            verarbeiteten personenbezogenen Daten ist Verantwortlicher im Sinne
            der DSGVO: Ingrid Fuerbass, Oberntorwall 21, 33602 Bielefeld,
            Telefon +491719350723, E-Mail info@fashionmonks.com (im Folgenden
            „wir“).
          </p>
          <h5>2. Wenn Sie unsere Webseite besuchen</h5>
          <p>
            Wenn Sie unsere Webseite besuchen, erhebt unser Server die folgenden
            Informationen von Ihrem Endgerät: Browser-Typ und -Version,
            Betriebssystem, die zuvor besuchte Webseite („Referrer“), IP-Adresse
            und Zeitpunkt des Seitenaufrufs.
          </p>
          <p>
            Wir erheben und verarbeiten diese Daten, um den störungsfreien
            Betrieb unserer Webseite sicherzustellen und einen Missbrauch
            unserer Leistungen erkennen, abwehren und verfolgen zu können.
            Ferner nutzen wir die erhobenen Daten zu statistischen Zwecken, um
            etwa auszuwerten, mit welchen Endgeräten und Browsern unsere
            Webseite aufgerufen wird, um auf dieser Basis unser Angebot laufend
            an die Bedürfnisse der Nutzer anzupassen und zu verbessern. Diese
            Datenverarbeitung erfolgt auf Grundlage von Artikel 6 Absatz 1
            Buchstabe f DSGVO.
          </p>
          <p>
            Alle oben genannten personenbezogenen Daten löschen wir spätestens
            zwölf Monate nach ihrer Erhebung.
          </p>
          <h5>3. Wenn Sie bei uns bestellen</h5>
          <p>
            Wenn Sie uns um ein Angebot bitten, verarbeiten wir Ihren Namen, die
            Lieferadresse und Ihre E-Mail- Adresse, wie Sie sie in Ihrer Anfrage
            angegeben haben. Soweit Sie bei Ihrer Bestellung freiwillig weitere
            Daten angeben (z.B. eine abweichende Rechnungsadresse oder eine
            Telefonnummer), verarbeiten wir auch diese Daten.
          </p>
          <p>
            Wir verarbeiten diese Daten elektronisch zur ordnungsgemäßen
            Vertragserfüllung, insbesondere für die Lieferung, die
            Rechnungsstellung, die Verbuchung von Zahlungen und die Bearbeitung
            von Retouren und Reklamationen. Sofern wir verpflichtet sind, Ihnen
            für ein digitales Produkt oder für eine Ware mit digitalen Elementen
            Aktualisierungen bereitzustellen, verarbeiten wir Ihre Kontaktdaten
            auch hierfür. Diese Datenverarbeitung erfolgt auf Grundlage von
            Artikel 6 Absatz 1 Buchstabe b DSGVO.
          </p>
          <p>
            Wir halten diese Daten gespeichert, bis alle gegenseitigen Ansprüche
            aus dem jeweiligen Vertragsverhältnis mit Ihnen vollständig erledigt
            und die handels- und steuerrechtlichen Aufbewahrungsfristen
            abgelaufen sind, denen wir unterliegen.
          </p>
          <p>
            Für einen Vertragsschluss zwischen Ihnen und uns ist es
            erforderlich, dass wir Ihren Namen, die Lieferadresse und Ihre
            E-Mail-Adresse erhalten. Die Erforderlichkeit der Bereitstellung
            dieser Daten ergibt sich aus gesetzlichen Vorschriften (etwa § 312i
            Abs. 1 Ziffer 3 BGB, § 14 Abs. 4 UStG). Ohne Bereitstellung dieser
            Daten können Sie mit uns daher keinen Vertrag schließen.
          </p>
          <p>
            Bei der Entscheidung über einen Vertragsschluss verzichten wir auf
            automatisierte Entscheidungsfindungen und auf Profiling.
          </p>
          <h5>4. Lieferung und Bezahlung</h5>
          <p>
            Soweit wir aufgrund des Kaufvertrags physische Waren übersenden,
            übermitteln wir den Namen und die Anschrift des Empfängers und,
            soweit Sie darin eingewilligt haben, Ihre E-Mail-Adresse, an
            Deutsche Post (Deutsche Post AG, 53113 Bonn), DHL (DHL Paket GmbH,
            53113 Bonn) oder Correos 07520 Petra, DHL Manacor als
            Versanddienstleister, und zwar zum Zwecke der Zustellung der
            Sendung, ggf. einschließlich einer vorherigen E-Mail-Ankündigung
            über den voraussichtlichen Zustellzeitpunkt, und zum Zwecke einer
            eventuellen Retoure Ihrer Sendung an uns auf Grundlage von Artikel 6
            Absatz 1 Buchstabe b DSGVO.
          </p>
          <p>
            Für die Bezahlung Ihres Einkaufs erhebt und verarbeitet der von
            Ihnen gewählte Zahlungsdienstleister Ihren Namen, Ihre
            E-Mail-Adresse, Ihre Karten- oder Kontonummer und/oder weitere
            Daten, soweit für die von Ihnen gewählte Zahlungsmethode jeweils
            erforderlich. Es gelten insoweit ergänzend die Vertrags- und
            Datenschutzbestimmungen des von Ihnen gewählten
            Zahlungsdienstleisters.
          </p>
          <p>
            Beim Empfang einer Zahlung verarbeiten wir diejenigen Daten, die uns
            der Zahlungsdienstleister übermittelt.
          </p>
          <p>
            Wenn wir eine Überweisung auf unser Konto erhalten, verarbeiten wir
            insbesondere den Namen des überweisenden Kontoinhabers, die
            Kontonummer (IBAN und BIC) und den mitübermittelten
            Verwendungszweck.
          </p>
          <p>
            Die Verarbeitung erfolgt auf Grundlage von Artikel 6 Absatz 1
            Buchstabe b DSGVO. Wir halten diese Daten gespeichert, bis alle
            gegenseitigen Ansprüche aus dem jeweiligen Vertragsverhältnis mit
            Ihnen vollständig erledigt und die handels- und steuerrechtlichen
            Aufbewahrungsfristen abgelaufen sind, denen wir unterliegen.
          </p>
          <h5>5. Auftragsverarbeiter</h5>
          <p>
            Für den Betrieb unserer Webseite im Internet nehmen wir die
            technischen Leistungen von checkdomain (checkdomain GmbH, 23552
            Lübeck) als Auftragsverarbeiter gemäß Artikel 28 DSGVO in Anspruch.
          </p>
          <h5>6. Kontaktaufnahme</h5>
          <p>
            Wenn Sie das Kontaktformular unserer Webseite nutzen, verarbeiten
            wir die von Ihnen in das Formular eingegebenen Daten, insbesondere
            Ihren Namen, Ihre E-Mail-Adresse und Ihre Nachricht.
          </p>
          <p>
            Wenn Sie uns eine Nachricht per E-Mail schicken, speichern wir Ihre
            Nachricht mit den damit übermittelten Absenderdaten (Name,
            E-Mail-Adresse und ggf. weitere von Ihrem E-Mail-Programm und den
            übermittelnden Servern hinzugefügte Informationen). Für den Empfang,
            die Speicherung und den Versand von E-Mails nutzen wir Leistungen
            von checkdomain (checkdomain GmbH, 23552 Lübeck) als
            Auftragsverarbeiter gemäß Artikel 28 DSGVO.
          </p>
          <p>
            Rechtsgrundlage für diese Datenverarbeitung ist unser berechtigtes
            Interesse, Ihre Nachricht zu beantworten und auch auf eventuelle
            Folgefragen von Ihnen reagieren zu können (Artikel 6 Absatz 1
            Buchstabe f DSGVO). Wir löschen die mit Ihrer Nachricht erhobenen
            Daten spätestens zwölf Monate nach der letzten mit Ihnen geführten
            Kommunikation zu Ihrem Anliegen, vorbehaltlich der Reglung im
            folgenden Absatz.
          </p>
          <p>
            Wenn Sie uns eine rechtlich relevante Erklärung zum
            Vertragsverhältnis übermitteln (z.B. einen Widerruf oder eine
            Reklamation), ist Rechtsgrundlage für die Verarbeitung, unabhängig
            vom Übermittlungsweg, auch Artikel 6 Absatz 1 Buchstabe b DSGVO. In
            einem solchen Fall löschen wir die mit Ihrer Erklärung
            zusammenhängenden Daten, sobald alle wechselseitigen Ansprüche aus
            dem Vertragsverhältnis endgültig erledigt und die handels- und
            steuerrechtlichen Aufbewahrungsfristen abgelaufen sind.
          </p>
          <h5>7. Ihre Rechte</h5>
          <p>
            Bezüglich der personenbezogenen Daten, die wir über Sie verarbeiten,
            stehen Ihnen die folgenden Rechte zu:
          </p>
          <p>
            Sie haben das Recht, von uns eine Bestätigung darüber zu verlangen,
            ob wir Sie betreffende personenbezogene Daten verarbeiten. Ist dies
            der Fall, so teilen wir Ihnen die über Sie gespeicherten
            personenbezogenen Daten und die weiteren Informationen gemäß Artikel
            15 Abs. 1 u. 2 DSGVO mit.
          </p>
          <p>
            Sie haben das Recht, Sie betreffende unrichtige personenbezogene
            Daten unverzüglich berichtigen zu lassen. Unter Berücksichtigung der
            Zwecke der Verarbeitung haben Sie auch das Recht, die
            Vervollständigung unvollständiger personenbezogener Daten – auch
            mittels einer ergänzenden Erklärung – zu verlangen.
          </p>
          <p>
            Sie können von uns die unverzügliche Löschung der Sie betreffenden
            personenbezogenen Daten unter den Voraussetzungen von Artikel 17
            Absatz 1 DSGVO verlangen, soweit deren Verarbeitung nicht gemäß
            Artikel 17 Absatz 3 DSGVO erforderlich ist.
          </p>
          <p>
            Sie können von uns die Einschränkung der Verarbeitung Ihrer Daten
            verlangen, wenn eine der Voraussetzungen von Artikel 18 Absatz 1
            DSGVO vorliegt. Sie können die Einschränkung insbesondere anstelle
            einer Löschung verlangen.
          </p>
          <p>
            Wir werden jede Berichtigung oder Löschung Ihrer personenbezogenen
            Daten und eine Einschränkung der Verarbeitung allen Empfängern
            mitteilen, denen wir Sie betreffende personenbezogene Daten
            offengelegt haben, es sei denn, dies erweist sich als unmöglich oder
            ist mit einem unverhältnismäßigen Aufwand verbunden. Wir werden Sie
            zudem über diese Empfänger unterrichten, wenn Sie dies verlangen.
          </p>
          <p>
            Sie haben das Recht, die personenbezogenen Daten, die Sie uns
            bereitgestellt haben, in einem strukturierten, gängigen und
            maschinenlesbaren Format zu erhalten, und können verlangen, dass wir
            diese Daten einem anderen Verantwortlichen ohne Behinderung
            übermitteln, soweit dies technisch möglich ist.
          </p>
          <p>
            Soweit eine Datenverarbeitung auf Ihrer Einwilligung beruht, haben
            Sie das Recht, Ihre Einwilligung jederzeit zu widerrufen. Durch den
            Widerruf der Einwilligung wird die Rechtmäßigkeit der
            Datenverarbeitung, die bis zu Ihrem Widerruf erfolgt ist, nicht
            berührt.
          </p>
          <p>
            <strong>
              WIDERSPRUCHSRECHT: AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN
              SITUATION ERGEBEN, KÖNNEN SIE JEDERZEIT DER VERARBEITUNG DER SIE
              BETREFFENDEN PERSONENBEZOGENEN DATEN WIDERSPRECHEN; dieses
              Widerspruchsrecht besteht in Bezug auf diejenige
              Datenverarbeitung, die auf Grundlage von Artikel 6 Absatz 1
              Buchstabe f DSGVO zur Wahrung berechtigter Interessen unsererseits
              oder eines Dritten erfolgt, sofern nicht Ihre Interessen oder
              Grundrechte und Grundfreiheiten, die den Schutz personenbezogener
              Daten erfordern, überwiegen. Wenn Sie Ihr Widerspruchsrecht
              ausüben, werden wir die betreffenden Daten nicht mehr verarbeiten,
              es sei denn, dass wir zwingende schutzwürdige Gründe für die
              Verarbeitung nachweisen können, die die Interessen, Rechte und
              Freiheiten von Ihnen überwiegen, oder dass die Verarbeitung der
              Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
              dient.
            </strong>
          </p>
          <p>
            <strong>
              FÜR DEN FALL, DASS WIR PERSONENBEZOGENE DATEN FÜR DIE
              DIREKTWERBUNG (Z.B. NEWSLETTER) VERARBEITEN, KÖNNEN SIE JEDERZEIT
              WIDERSPRUCH GEGEN DIE VERARBEITUNG DER SIE BETREFFENDEN
              PERSONENBEZOGENEN DATEN ZUM ZWECKE DERARTIGER WERBUNG EINLEGEN MIT
              DER FOLGE, DASS WIR IHRE DATEN FÜR DIESE ZWECKE NICHT MEHR
              VERARBEITEN WERDEN.
            </strong>
          </p>
          <p>
            Wenn Sie der Ansicht sind, dass die Verarbeitung Ihrer
            personenbezogenen Daten gegen die DSGVO verstößt, können Sie
            Beschwerde einlegen bei einer Aufsichtsbehörde, insbesondere in dem
            Mitgliedstaat Ihres Aufenthaltsorts, Ihres Arbeitsplatzes oder des
            Orts des mutmaßlichen Verstoßes. Das schließt anderweitige
            behördliche oder gerichtliche Rechtsbehelfe nicht aus.
          </p>
        </section>
      ) : (
        <section className="legal">
          <h4>Privacy Policy</h4>
          <p>
            Hereinafter we inform you about the nature, scope and purpose of the
            processing of your personal data when using our online services at „
            auf „www.fashionmonks.com““. Personal data is any information that
            relates to an identified or identifiable natural person.
          </p>
          <h5>1. Controller</h5>
          <p>
            The person responsible (“Controller”) within the meaning of the EU
            General Data Protection Regulation (GDPR) is the natural or legal
            person which, alone or jointly with others, determines the purposes
            and means of the processing of personal data. Controller within the
            meaning of the GDPR for the personal data processed by us is Ingrid
            Fuerbass, Oberntorwall 21, 33602 Bielefeld, phone +491719350723,
            e-mail info@fashionmonks.com (hereinafter “we”).
          </p>
          <h5>2. When you visit our web site</h5>
          <p>
            When you visit our website, our server collects the following
            information from your device: browser type and version, operating
            system used, the previously visited web page, IP address, and time
            of the page view.
          </p>
          <p>
            We collect and process this data in order to ensure the trouble-free
            operation of our website and to detect, fend off and prosecute a
            misuse of our services. Furthermore, we use the collected data for
            statistical purposes to evaluate, for example, by which devices and
            browsers our website is accessed in order to improve and adapt our
            offer to our customers’ needs on an ongoing basis. This data
            processing is based on Article 6 par. 1 f GDPR.
          </p>
          <p>
            We will delete the aforementioned data no later than twelve months
            after they have been collected.
          </p>
          <h5>3. When you place an order</h5>
          <p>
            When you request an offer from us, we process your name, the
            delivery address, and your e-mail address, as provided by you in
            your request. We will also process any additional information
            provided by you voluntarily (such as a differing billing address or
            a telephone number).
          </p>
          <p>
            We process this data electronically for the proper performance of
            the contract, in particular for shipping, invoicing, accounting, and
            processing of returns and complaints. Where we are obligated to
            provide you with updates for a digital product or for a product with
            digital elements, we will process your contact information for this
            purpose as well. This data processing is based on Article 6 par. 1 b
            GDPR.
          </p>
          <p>
            We store this data until all mutual claims arising from the
            respective contractual relationship with you have been completely
            settled and the commercial and fiscal retention periods to which we
            are subject have expired.
          </p>
          <p>
            To conclude a contract between you and us, it is necessary that we
            receive your name, delivery address and e-mail address. The
            necessity of providing this data arises from various statutory
            regulations (eg. § 312i par. 1 and 3 BGB [German Civil Code], § 14
            par. 4 UStG [German Turnover Tax Act]). Without providing this data,
            you cannot conclude a contract with us.
          </p>
          <p>
            We refrain from using automated decision-making or profiling for
            deciding whether or not to conclude a contract.
          </p>
          <h5>4. Shipping and Payment</h5>
          <p>
            When we ship physical goods in order to perform a contract, we may
            transmit the recipient’s name and address, and, if you have given
            your consent, your e-mail address, to Deutsche Post (Deutsche Post
            AG, 53113 Bonn), DHL (DHL Paket GmbH, 53113 Bonn) or Correos 07520
            Petra, DHL Manacor as our shipping service provider for the purpose
            of delivering the shipment, including, if applicable, a prior e-mail
            notification of the expected time of delivery, and, if necessary,
            for returns back to us, on the basis of Article 6 par. 1 b GDPR.
          </p>
          <p>
            To process your payment, the payment service provider chosen by you
            will collect and process your name, your credit card or account
            number and/or further data necessary for the chosen payment method.
            The privacy policy of the payment service provider chosen by you
            shall apply.
          </p>
          <p>
            Upon receipt of a payment, we process the data transmitted to us by
            the payment service provider.
          </p>
          <p>
            When we receive a transfer to our account, we process in particular
            the name of the transferring account holder, the account number
            (IBAN and BIC) and the purpose of the transfer.
          </p>
          <p>
            This data processing takes place according to Article 6 par. 1 b
            GDPR. We shall store this data until all mutual claims arising from
            the respective contractual relationship with you have been
            completely settled and all commercial and fiscal retention periods
            to which we are subject have expired.
          </p>
          <h5>5. Processors</h5>
          <p>
            For the operation of our website on the Internet, we use technical
            services provided by checkdomain (checkdomain GmbH, 23552 Lübeck) as
            Processor according to Article 28 GDPR.
          </p>
          <h5>6. Contacting us</h5>
          <p>
            If you send us a message via the contact form on our website, we
            will process all data input by you, especially your name, your
            e-mail address along with your message.
          </p>
          <p>
            If you send us a message by e-mail, we will save your message along
            with the sender details (your name, email address, and any
            additional information added by your e-mail program or the
            transferring servers). For reception, storage and sending of
            e-mails, we use processing services of checkdomain (checkdomain
            GmbH, 23552 Lübeck) in accordance with Article 28 GDPR.
          </p>
          <p>
            This data processing is based on our legitimate interest to answer
            your request and handle possible followup requests from you (Article
            6 par. 1 f GDPR). We will erase the information collected from your
            message no later than twelve months after the last communication
            with you on your request, subject to the provision in the following
            paragraph.
          </p>
          <p>
            If you send us a message with information legally relevant for the
            contractual relationship (e.g. a withdrawal or a complaint), the
            legal basis for the processing is Article 6 par. 1 b GDPR,
            regardless of how you transmitted your message to us. In such a
            case, we will erase the data related to your message as soon as all
            mutual claims arising from the contractual relationship have been
            completely settled and the commercial and fiscal retention periods
            have expired.
          </p>
          <h5>7. Your Rights</h5>
          <p>
            With regard to your personal data we process, you have the following
            rights:
          </p>
          <p>
            You have the right to obtain a confirmation from us as to whether we
            process personal data concerning you. If this is the case, we will
            inform you about the personal data stored about you and the further
            information in accordance with Article 15 par. 1 and 2 GDPR.
          </p>
          <p>
            You have the right to have your inaccurate personal data rectified
            without undue delay. Taking into account the purposes of processing,
            you also have the right to request the completion of incomplete
            personal data, including by means of providing a supplementary
            statement.
          </p>
          <p>
            You can demand the erasure of your personal data concerning you
            under the conditions of Article 17 par. 1 GDPR without undue delay,
            as far as their processing is not necessary according to Article 17
            par. 3 GDPR.
          </p>
          <p>
            You may demand that we restrict the processing of your data if one
            of the requirements of Article 18 par. 1 GDPR applies. In
            particular, you can request the restriction instead of an erasure.
          </p>
          <p>
            We will communicate any rectification or erasure of your personal
            data and a restriction of processing to all recipients to whom we
            have disclosed your personal data, unless this proves impossible or
            involves a disproportionate effort. We will also inform you about
            these recipients if you request it.
          </p>
          <p>
            You have the right to receive the personal data which you provide to
            us in a structured, commonly used and machine-readable format. You
            may also request that we transmit the data to another controller
            without hindrance, where technically feasible.
          </p>
          <p>
            As far as a data processing is based on your given consent, you have
            the right to, withdraw your consent at any time. The withdrawal of
            consent shall not affect the lawfulness of the data processing based
            on consent before its withdrawal.
          </p>
          <p>
            <strong>
              RIGHT TO OBJECT: ON GROUNDS RELATING TO YOUR PARTICULAR SITUATION,
              YOU MAY, AT ANY TIME, OBJECT TO PROCESSING OF YOUR PERSONAL DATA;
              this right applies to a processing, according to Article 6 par. 1
              f GDPR, necessary for the purposes of the legitimate interests
              pursued by us or by a third party, except where such interests are
              overridden by your interests or fundamental rights and freedoms of
              the data subject which require protection of personal data, in
              particular where the data subject is a child. If you exercise your
              right to object, we will no longer process the personal data in
              question unless we can demonstrate compelling legitimate grounds
              for processing that override the interests, rights and freedoms of
              you, or for the establishment, exercise or defense of legal
              claims.
            </strong>
          </p>
          <p>
            <strong>
              IN CASE WE PROCESS PERSONAL DATA FOR DIRECT MARKETING PURPOSES
              (E.G. NEWSLETTER), YOU MAY, AT ANY TIME, OBJECT TO PROCESSING OF
              YOUR PERSONAL DATA FOR THE PURPOSE OF SUCH ADVERTISING, WITH THE
              RESULT THAT WE WILL NO LONGER PROCESS YOUR DATA FOR THESE
              PURPOSES.
            </strong>
          </p>
          <p>
            If you believe that the processing of your personal data is in
            breach of the GDPR, you may lodge a complaint with a supervisory
            authority, in particular in the Member State of your habitual
            residence, place of work or place of the alleged infringement. This
            does not exclude other administrative or judicial remedies.
          </p>
        </section>
      )}
    </>
  );
};

export default Datenschutzerklaerung;
