import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { ProductsResponsive } from "../../services/responsive";
import ProductCardElement from "./utility";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Popup from "../Popup";
import Button from "react-bootstrap/Button";

export function ProductCard(props) {
  const slidesss = props.slides;

  const [buttonPopup, setButtonPopup] = useState(false);
  const [slidess, setSlidess] = useState(slidesss);
  const [image, setImage] = useState();

  function handleOnClick(index) {
    setButtonPopup(true);
    setImage(
      slidesss.filter(function (i) {
        return i.id === index;
      })[0]
    );
  }

  useEffect(() => {
    setSlidess(slidess);
  }, [slidess]);

  function onClick(collection) {
    switch (collection) {
      case "0":
        setSlidess(slidesss);
        break;
      case "1":
        setSlidess(
          slidesss.filter(function (i) {
            return i.collection === "Kaftan Avant Garde Dresses";
          })
        );
        break;
      case "2":
        setSlidess(
          slidesss.filter(function (i) {
            return i.collection === "Oversize Dresses + Boho Chic";
          })
        );
        break;
      case "3":
        setSlidess(
          slidesss.filter(function (i) {
            return i.collection === "In Love with Black & White";
          })
        );
        break;
      case "4":
        setSlidess(
          slidesss.filter(function (i) {
            return i.collection === "Happy Hippie Hop";
          })
        );
        break;
      default:
        console.log(`Sorry, we are out of ${collection}.`);
    }
  }

  return (
    <div className="productSlider">
      <div className="buttonGroup">
        <Button className="custom-btn" onClick={() => onClick("1")}>
          Kaftan Avantgarde
        </Button>
        <Button className="custom-btn" onClick={() => onClick("2")}>
          Oversize-Look + Boho Chic
        </Button>
        <Button className="custom-btn" onClick={() => onClick("3")}>
          In Love with Black & White
        </Button>
        <Button className="custom-btn" onClick={() => onClick("4")}>
          Cool Outfits
        </Button>
        <Button className="custom-btn" onClick={() => onClick("0")}>
          All
        </Button>
      </div>
      <Container>
        <Carousel
          arrows={!buttonPopup}
          controls="false"
          infinite={true}
          responsive={ProductsResponsive}
          autoPlay={true}
          autoPlaySpeed={5000}
          keyBoardControl={true}
        >
          {slidess.map((slide, index) => (
            <ProductCardElement
              key={index}
              slidePro={slide}
              handleOnClick={handleOnClick}
            />
          ))}
        </Carousel>
      </Container>

      <Popup
        trigger={buttonPopup}
        setTrigger={setButtonPopup}
        image={image}
        language={props.language}
      ></Popup>
    </div>
  );
}

export default ProductCard;
