import { Outlet } from "react-router-dom";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Root = ({ language, setLanguage }) => {
  return (
    <>
      <Navbar language={language} setLanguage={setLanguage} />
      <Outlet />
      <Footer language={language} />
    </>
  );
};

export default Root;
