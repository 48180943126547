import React from "react";

const Geschaftsbedingungen = (props) => {
  return (
    <>
      {props.language === 1 ? (
        <section className="legal">
          <h3>Allgemeine Geschäftsbedingungen</h3>
          <h4>§ 1 – Anbieter, Einbeziehung der AGB</h4>
          <p>
            <strong>(1)</strong> Anbieter und Vertragspartner für die auf
            www.fashionmonks.com online dargestellten Waren ist Ingrid Fuerbass,
            Oberntorwall 21, 33602 Bielefeld, Telefon +491719350723, E-Mail
            info@fashionmonks.com (im Folgenden kurz „Anbieter“).
          </p>
          <p>
            <trong>(2)</trong> Diese allgemeinen Geschäftsbedingungen sind
            Bestandteil jeder vertraglichen Vereinbarung zwischen dem Anbieter
            und dem jeweiligen Kunden. Entgegenstehenden AGB des Kunden wird
            widersprochen.
          </p>
          <h4>§ 2 – Angebot und Vertragsschluss</h4>
          <p>
            <strong>(1)</strong> Der Anbieter bietet die aufgeführten Artikel
            zum Kauf an. Die farbliche Darstellung der Artikel kann abhängig von
            Internetbrowser und Monitoreinstellungen des Kunden geringfügig
            variieren; diese Abweichungen sind technisch nie ganz vermeidbar.
            Angebot, Vertragsschluss und Vertragsabwicklung erfolgen nach Wahl
            des Kunden in deutscher oder englischer Sprache.
          </p>
          <p>
            <strong>(2)</strong> Bei Interesse kann der Kunde unter Angabe
            seines Namens, seiner E-Mail-Adresse, der Lieferanschrift und der
            gewünschten Ware den Anbieter kontaktieren. Der Anbieter kann nicht
            gewährleisten, dass jeder Artikel in der gewünschten Menge oder
            Ausführung verfügbar ist. Daher prüft der Anbieter nach Eingang
            einer Kaufanfrage zunächst, inwieweit er den Kundenwunsch erfüllen
            kann. Soweit die gewünschte Ware lieferbar ist, übersendet der
            Anbieter dem Besteller ein individuelles Angebot per E-Mail.
          </p>
          <p>
            <strong>(3)</strong> Ab Angebotsversand hält sich der Anbieter eine
            Woche an sein Angebot gebunden. Der Kunde kann das Angebot während
            der Bindungsfrist durch eine Bestätigung per E-Mail annehmen,
            wodurch der Kaufvertrag zustande kommt. Nach Ablauf der
            Bindungsfrist eingehende Bestätigungen führen nur dann zum
            Vertragsschluss, wenn der Anbieter das Angebot des Kunden
            seinerseits bestätigt. Der Anbieter bestätigt den Vertragsschluss
            per E-Mail (Vertragsbestätigung).
          </p>
          <p>
            <strong>(4)</strong> Der Anbieter speichert die an den Kunden
            versandten E-Mails, die Angebot und Vertragsbestätigung enthalten.
            Darüber hinaus speichert der Anbieter den Vertragstext nicht und
            stellt ihn dem Kunden auch nicht in sonstiger Weise zur Verfügung.
            Dem Kunden wird daher empfohlen, die E-Mails des Anbieters zu
            speichern und/oder auszudrucken.
          </p>

          <h4>§ 3 – Preise und Bezahlung</h4>

          <p>
            <strong>(1)</strong> Alle Warenpreise verstehen sich als
            Gesamtpreise. Umsatzsteuer wird nicht ausgewiesen (§ 19 UStG).
          </p>
          <p>
            <strong>(2)</strong> Zur Bezahlung seines Einkaufs kann der Kunde
            folgende Zahlungsmethoden nutzen: Vorkasse per Banküberweisung,
            PayPal.
          </p>
          <p>
            <strong>(3)</strong> Bei vereinbarter Vorauszahlung des Kaufpreises
            gilt eine Zahlungsfrist von einer Woche ab Zugang der
            Vertragsbestätigung. Der Anbieter legt die gewünschte Ware während
            der Dauer der Zahlungsfrist für den Kunden zurück. Der Anbieter
            behält sich das Recht vor, vom Vertrag zurückzutreten und die Ware
            anderweitig zu verkaufen, wenn die Zahlung nicht rechtzeitig bewirkt
            worden ist.
          </p>

          <h4>§ 4 – Versand und Lieferzeit</h4>

          <p>
            <strong>(1)</strong> Angaben zur Lieferzeit ergeben sich aus dem
            individuellen Angebot. Eine Angabe in Tagen bezieht sich auf die
            Zeitspanne von der Zahlung durch den Kunden bis zur Zustellung der
            Lieferung.
          </p>
          <p>
            <strong>(2)</strong> Wenn eine Lieferung fehlschlägt, weil der Kunde
            die Lieferadresse falsch oder unvollständig angegeben hat, erfolgt
            ein erneuter Zustellversuch nur, wenn der Kunde die unmittelbaren
            Kosten des erneuten Versands übernimmt.
          </p>

          <h4>§ 5 – Verbraucher-Widerrufsrecht</h4>
          <p>
            <strong>(1)</strong> Einem Kunden, der als Verbraucher kauft, steht
            ein Widerrufsrecht gemäß den gesetzlichen Bedingungen zu.
            Verbraucher ist jede natürliche Person, die ein Rechtsgeschäft zu
            Zwecken abschließt, die überwiegend weder ihrer gewerblichen noch
            ihrer selbständigen beruflichen Tätigkeit zugerechnet werden können.
          </p>
          <p>
            <strong>(2)</strong> Einem Verbraucher mit ständigem Aufenthalt
            außerhalb Deutschlands räumt der Anbieter ein
            Verbraucher-Widerrufsrecht gemäß den deutschen Voraussetzungen und
            Rechtsfolgen auch dann ein, wenn das nationale Recht des
            Verbrauchers kein Widerrufsrecht vorsieht oder den Widerruf an eine
            kürzere Frist oder an eine strengere Form knüpft als nach deutschem
            Recht.
          </p>

          <h4>§ 6 – Gewährleistung (Mängelhaftung)</h4>
          <p>
            Es bestehen Gewährleistungsansprüche des Bestellers (auch
            Mängelhaftungsrechte genannt) gemäß den gesetzlichen Vorschriften.
          </p>
          <h4>§ 7 – Außergerichtliche Streitbeilegung</h4>
          <p>
            <strong>(1)</strong> Zur außergerichtlichen Beilegung von
            verbraucherrechtlichen Streitigkeiten stellt die Europäische Union
            eine Online-Plattform („OS-Plattform”) zur Verfügung unter der
            Adresse{" "}
            <a href="https://ec.europa.eu/consumers/odr">
              https://ec.europa.eu/consumers/odr
            </a>
          </p>
          <p>
            <strong>(2)</strong> Wir sind weder verpflichtet noch bereit, an
            einem Streitbeilegungsverfahren vor einer
            Verbraucherschlichtungsstelle teilzunehmen.
          </p>
          <h4>§ 8 – Schlussbestimmungen</h4>
          <p>
            <strong>(1)</strong> Es gilt das Recht der Bundesrepublik
            Deutschland unter Ausschluss des UN-Kaufrechts. Gegenüber
            Verbrauchern mit ständigem Aufenthalt im Ausland findet das deutsche
            Recht jedoch keine Anwendung, soweit das nationale Recht des
            Verbrauchers Regelungen enthält, von denen zu Lasten des
            Verbrauchers nicht durch Vertrag abgewichen werden kann.
          </p>
          <p>
            <strong>(2)</strong> Sollten sich einzelne Bestimmungen dieser
            allgemeinen Geschäftsbedingungen als unwirksam oder undurchführbar
            erweisen, so berührt dies nicht die Wirksamkeit der übrigen
            Bestimmungen.
          </p>
        </section>
      ) : (
        <section className="legal">
          <h3>General Terms and Conditions</h3>
          <h4>
            Section 1 – Vendor, inclusion by reference of the General Terms and
            Conditions
          </h4>
          <p>
            <strong>(1)</strong> The Vendor and contracting party for the
            merchandise presented online at auf „www.fashionmonks.com“ is Ingrid
            Fuerbass, Oberntorwall 21, 33602 Bielefeld, phone +491719350723,
            e-mail info@fashionmonks.com (referred to hereinbelow as the
            “Vendor” for short).
          </p>
          <p>
            <strong>(2)</strong> The present General Terms and Conditions are a
            component part of any contractual agreement made between the Vendor
            and the respective Customer. The Vendor hereby objects to any terms
            and conditions that the Customer may have established and that
            contradict the present General Terms and Conditions.
          </p>
          <h4>Section 2 – Offer and conclusion of contract</h4>
          <p>
            <strong>(1)</strong> The Vendor is offering the goods for sale. The
            colors of the goods may vary slightly depending on the internet
            browser and monitor settings used by the Customer; these variations
            are technically unavoidable. Offer, conclusion of contract and
            implementation of the agreement shall all take place in German or
            English to the Customer’s discretion
          </p>
          <p>
            <strong>(2)</strong> If the Customer is interested, he can contact
            the Vendor and ask for a quotation by stating his name, his e-mail
            address, the delivery address and the desired goods. The Vendor
            cannot guarantee that all the goods are available in the desired
            quantity or variant. Therefore, after receiving a purchase request,
            the Vendor first checks the extent to which he can fulfil the
            customer’s wishes. As far as the desired merchandise is available,
            the Vendor provides the Customer with an individual offer by e-mail.
          </p>
          <p>
            <strong>(3)</strong> The offer shall bind the Vendor for one week.
            Until expiration of the commitment period, the Customer can accept
            the offer by e-mail, which will result in the conclusion of the
            purchase contract. Confirmations received after expiry of the
            commitment period shall be handled as new offer from the Customer
            and only lead to a sales contract if the Vendor confirms the
            Customer’s offer. The Vendor confirms the conclusion of the contract
            by e-mail. (confirmation of contract).
          </p>
          <p>
            <strong>(4)</strong> The Vendor saves the offer and the contract
            confirmation sent by e-mail to the Customer. Beyond that, the Vendor
            will neither save the contract text nor make it accessible to the
            Customer. Therefore, the Customer is advised to save the e-mails
            received from the Vendor for later reference.
          </p>

          <h4>Section 3 – Prices and payment</h4>

          <p>
            <strong>(1)</strong> All product prices are total prices plus
            shipping costs. VAT will not be shown on invoices (§ 19 UStG)
          </p>
          <p>
            <strong>(2)</strong> The Customer can use the following payment
            methods to pay for his purchase: Advance payment by bank transfer,
            PayPal.
          </p>
          <p>
            <strong>(3)</strong> For orders subject to pre-payment, a payment
            period of one week from the contract confirmation shall apply. For
            the term of the payment period, the Vendor shall reserve the goods
            so ordered for the Customer. The Vendor reserves the right to
            rescind the purchase contract and to sell the goods to others should
            the payment not be made in good time.
          </p>

          <h4>Section 4 – Shipment, delivery periods</h4>

          <p>
            <strong>(1)</strong> Information on delivery time can be found in
            the individual offer. An indication in days refers to the period
            from the payment by the Customer until delivery of the goods.
          </p>
          <p>
            <strong>(2)</strong> Where a delivery cannot be made because the
            Customer has provided a wrong or incomplete shipping address, an
            attempt to once again deliver the goods shall be made only if the
            Customer accepts to bear the costs of re-shipping the goods.
          </p>

          <h4>Section 5 – Right of withdrawal for consumers</h4>
          <p>
            <strong>(1)</strong> A Customer purchasing as consumer is entitled
            to a right of withdrawal in accordance with the statutory
            pre-requisites. A consumer means every natural person who enters
            into a legal transaction for a purpose that is mainly outside his
            commercial or self-employed professional activity.
          </p>
          <p>
            <strong>(2)</strong> The pre-requisites and legal consequences of
            the German stipulations as to Consumers’ rights of withdrawal shall
            apply to consumers having their permanent residence outside of
            Germany also in those cases in which the Consumer’s national law
            does not provide for a right of withdrawal or provides for a shorter
            withdrawal period or for stricter requirements as to form.
          </p>

          <h4>Section 6 – Warranty</h4>
          <p>Warranty claims shall be governed by the statutory regulations.</p>
          <h4>Section 7 – Out-of-court Dispute Resolution</h4>
          <p>
            <strong>(1)</strong> For settling consumer disputes out-of-court,
            the European Union offers a platform for online dispute resolution
            (“ODR platform“) at{" "}
            <a href="https://ec.europa.eu/consumers/odr">
              https://ec.europa.eu/consumers/odr
            </a>
          </p>
          <p>
            <strong>(2)</strong> We are neither obliged nor willing to
            participate in consumer dispute resolution proceedings.
          </p>
          <h4>Section 8 – Final provisions</h4>
          <p>
            <strong>(1)</strong> The laws of the Federal Republic of Germany
            shall apply, to the exclusion of the United Nations Convention on
            the International Sale of Goods (UNCISG). However, German law shall
            not apply to transactions with consumers having their permanent
            residence abroad to the extent the national laws applicable to those
            consumers set out provisions that cannot be contracted out to the
            detriment of the consumers.
          </p>
          <p>
            <strong>(2)</strong> Should individual provisions of the present
            General Terms and Conditions prove to be invalid or impossible to
            implement, this shall not affect the validity of the remaining
            provisions.
          </p>
        </section>
      )}
    </>
  );
};

export default Geschaftsbedingungen;
