import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import Card from "react-bootstrap/Card";
import "./style.css";

function ProductCardElement(props) {

  return (
    <div className="text-center" key={props.slidePro.id}>
      <Card className="border-0 position-relative">
          <Card.Img
            variant="top"
            src={props.slidePro.src}
            onClick={() => props.handleOnClick(props.slidePro.id)}
          />
          <Card.Body>
            <Card.Title>
              {props.slidePro.name}
            </Card.Title>
        </Card.Body>
      </Card>
    </div>
  );
}

export default ProductCardElement
