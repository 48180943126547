import React from "react";
import { useRef } from "react";

import "./Hero.css";
import logo from "./images/logoPopup.png";
import home from "./images/homeLogo.png";
import design from "./images/Design.png";
import creative from "./images/Creative.png";

const Hero = (props) => {
  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  return (
    <div className="hero" id="hero">
      {windowSize.current[0] <= 940 ? (
        <div className="container">
          <div className="row">
            <img className="logoImage" src={logo} alt="logo" />
          </div>
          <div className="row">
            <div className="col-sm">
              <img className="homeImage" src={home} alt="logo" />
            </div>
          </div>
          <div className="row imgs">
            <div className="col">
              <img
                className="creativeImage"
                src={creative}
                alt="creative"
                style={{ alignSelf: "center" }}
              />
            </div>
            <div className="col">
              <img
                className="designImage"
                src={design}
                alt="design"
                style={{ alignSelf: "center" }}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="container">
          <div className="row">
            <img className="logoImage" src={logo} alt="logo" />
          </div>
          <div className="row">
            <div className="col-sm">
              <img
                className="creativeImage"
                src={creative}
                alt="creative"
                style={{ alignSelf: "center" }}
              />
            </div>
            <div className="col-sm">
              <img className="homeImage" src={home} alt="logo" />
            </div>
            <div className="col-sm">
              <img
                className="designImage"
                src={design}
                alt="design"
                style={{ alignSelf: "center" }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Hero;
