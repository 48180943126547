import React from "react";

import "./Footer.css";
import { Link } from "react-router-dom";

const Footer = (props) => {
  return (
    <div className="footer">
      <div className="bottom">
        {props.language === 1 ? (
          <div className="links">
            <p className="textFooter">2024 FashionMonks</p>
            <div className="textContainer">
              <Link className="textFooter" to={"geschaftsbedingungen"}>
                Allgemeine Geschaeftsbedingungen{" "}
              </Link>
              <Link className="textFooter" to={"widerrufsbelehrung"}>
                Widerrufsbelehrung{" "}
              </Link>
              <Link className="textFooter" to={"datenschutzerklaerung"}>
                Datenschutzerklaerung{" "}
              </Link>
              <Link className="textFooter" to={"impressum"}>
                Impressum
              </Link>
            </div>
            {/*             <a
              className="ssl"
              href="https://www.checkdomain.de/unternehmen/garantie/ssl/popup/"
            >
              <img
                src="https://www.checkdomain.de/assets/bundles/web/app/widget/seal/img/ssl_certificate/de/150x150.png"
                alt="SSL-Zertifikat"
              />
            </a> */}
          </div>
        ) : (
          <div>
            <p className="textFooter">2024 FashionMonks</p>
            <div className="textContainer">
              <Link className="textFooter" to={"geschaftsbedingungen"}>
                Terms and conditions{" "}
              </Link>
              <Link className="textFooter" to={"widerrufsbelehrung"}>
                Revocation information{" "}
              </Link>
              <Link className="textFooter" to={"datenschutzerklaerung"}>
                Privacy policy{" "}
              </Link>
              <Link className="textFooter" to={"Impressum"}>
                Imprint
              </Link>
            </div>
            {/*  <a
              className="ssl"
              href="https://www.checkdomain.de/unternehmen/garantie/ssl/popup/"
            >
              <img
                src="https://www.checkdomain.de/assets/bundles/web/app/widget/seal/img/ssl_certificate/de/150x150.png"
                alt="SSL-Zertifikat"
              />
            </a> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default Footer;
