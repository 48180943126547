import React from "react";
import "./Popup.css";
import logoPopup from "./images/logoPopup.png";

function Popup(props) {
  return props.trigger ? (
    <>
      <div className="popup">
        <div className="popup-inner">
          <div className="d-flex popup-container">
            <img
              className="img"
              width="auto"
              height="auto"
              src={props.image.src}
              alt={props.image.alt}
            />
            <div
              className=""
              //className="columnPopup"
            >
              <div className="name text-center">
                <div className="text">{props.image.name}</div>
              </div>
              <div className="containerPopup">
                <div className="text">{props.image.description}</div>
                <div className="text2">{props.image.material}</div>
                <div className="text2">{props.image.size}</div>
                {props.language === 1 ? (
                  <div className="text2">Kontakt via E-Mail</div>
                ) : (
                  <div className="text2">Contact via email</div>
                )}
              </div>

              <div className="logo">
                <img className="imgLogo" src={logoPopup} alt="logo" />
              </div>
              <button
                className="close-btn"
                onClick={() => props.setTrigger(false)}
              >
                X
              </button>
            </div>
          </div>
          {/* <div className="rowPopup">
            <div className="columnPopup">
              <div className="imgPop">
                <img
                  className="imgPopup"
                  src={props.image.src}
                  alt={props.image.alt}
                />
              </div>
            </div>
            <div className="columnPopup">
              <div className="name">
                <div className="text">{props.image.name}</div>
              </div>
              <div className="containerPopup">
                <div className="text">{props.image.description}</div>
                <div className="text2">{props.image.material}</div>
                <div className="text2">{props.image.size}</div>
                {props.language === 1 ? (
                  <div className="text2">Kontakt via E-Mail</div>
                ) : (
                  <div className="text2">Contact via email</div>
                )}
              </div>

              <div className="logo">
                <img className="imgLogo" src={logoPopup} alt="logo" />
              </div>
              <button
                className="close-btn"
                onClick={() => props.setTrigger(false)}
              >
                X
              </button>
            </div>
          </div> */}
        </div>
      </div>
    </>
  ) : (
    ""
  );
}

export default Popup;
