const allProductsGerman = [
  {
    id: 0,
    src: "/showroom/BohoChic/LINN.jpg",
    name: "LINN",
    description:
      "Mit dem schönen pastellfarbenen Patchwork Design, und dem originellen lockeren Ballonschnitt macht jede Frau eine tolle Figur. Ein Blickfang und must-have im Sommer.",
    material: "Material: 100% Baumwolle",
    size: "Einheitsgröße / Übergröße",
    collection: "Oversize Dresses + Boho Chic",
  },
  {
    id: 1,
    name: "ISA-BELLA",
    src: "/showroom/BohoChic/ISA-BELLA.jpg",
    description:
      "Der stilvolle schöne Druck ist ein echter Blickfang, und das wunderbare Seidenmaterial dieses Kleides sorgt für feine Eleganz und ist sehr angenehm auf der Haut zu tragen. Bringen Sie Farbe ins Spiel für Ihren großen Auftritt.",
    material: "Material: 100% Seide",
    size: "Einheitsgröße / Übergröße",
    collection: "Oversize Dresses + Boho Chic",
  },
  {
    id: 2,
    name: "YOKO",
    src: "/showroom/BohoChic/YOKO.jpg",
    description:
      "Extravaganter und eleganter Chic-Look in einem leichten schönen Material. Das Modell hat einen lockeren Schnitt im Stil eines Kaftans. Die raffinierte Raffung im Taillenbereich umschmeichelt die Figur auf ansprechende Weise.",
    material: "Material: 60% Baumwolle 40% Polyester",
    size: "Einheitsgröße / Übergröße",
    collection: "Oversize Dresses + Boho Chic",
  },
  {
    id: 3,
    name: "CHAKA",
    src: "/showroom/BohoChic/CHAKA.jpg",
    description:
      "Der schöne dezente Tigerprint gibt diesem Modell im leicht fliessenden Viskosestoff eine exotische Note, und die formgebende Raffung in der Taille erzeugt ein Hingucker Modell.",
    material: "Material: 100% Viskose",
    size: "Einheitsgröße / Übergröße",
    collection: "Oversize Dresses + Boho Chic",
  },
  {
    id: 4,
    name: "IMKE",
    src: "/showroom/BohoChic/IMKE.jpg",
    description:
      "Das frische Grün bringt gute Laune und positive Energie, und der schwungvolle Schnitt verströmt Boho-Vibes. Mit diesem figurschmeichelnden Kleid wird der Sommer bunt und fröhlich.",
    material: "Material: 100% Baumwolle",
    size: "Einheitsgröße / Übergröße",
    collection: "Oversize Dresses + Boho Chic",
  },
  {
    id: 5,
    name: "LIV",
    src: "/showroom/BohoChic/LIV.jpg",
    description:
      "Das ausgefallene Patchwork-Design in schönen Pastellfarben wirkt frisch und belebend, und der individuelle Ballonschnitt verleiht dem Kleid eine lässige Note. Da kommt keine Langeweile auf.",
    material: "Material: 100% Baumwolle",
    size: "Einheitsgröße / Übergröße",
    collection: "Oversize Dresses + Boho Chic",
  },
  {
    id: 6,
    name: "YUNA",
    src: "/showroom/BohoChic/YUNA.jpg",
    description:
      "Entspannte Silhouette, raffinierte Details und feminine Eleganz in dezentem Schwarz-Weiß-Look. In dem festeren Material ist dieses Modell in allen Jahreszeiten tragbar.",
    material: "Material: 95% Polyester 5% Elastan",
    size: "Einheitsgröße / Übergröße",
    collection: "Oversize Dresses + Boho Chic",
  },
  {
    id: 7,
    name: "GAGA",
    src: "/showroom/BohoChic/GAGA.jpg",
    description:
      "Schickes cooles Ballonkleid mit leicht körpernahem Schnitt. Durch das farbenfrohe, wunderschöne Design und das glänzende, weich fließende Material erhält dieses Outfit eine ganz besondere Note. Was braucht man mehr für einen stilvollen Auftritt?.",
    material: "Material: 100% Polyester \n (Satin-Polyester, leicht dehnbar)",
    size: "Einheitsgröße / Übergröße",
    collection: "Oversize Dresses + Boho Chic",
  },
  {
    id: 8,
    name: "GAGA JACKE",
    src: "/showroom/BohoChic/GAGA JACKIE.jpg",
    description:
      "Diese leicht oversized lange Jacke, sorgt mit der lockeren Passform für eine lässige Note, und ist eine schöne Ergänzung zum Ballonkleid. Solo getragen extravagant, und facettenreich in Kombination mit vielen unifarbenen Kleidungsstücken.",
    material: "Material: 100% Polyester \n (Satin-Polyester, leicht dehnbar)",
    size: "Einheitsgröße / Übergröße",
    collection: "Oversize Dresses + Boho Chic",
  },
  {
    id: 9,
    name: "GAGA TOP",
    src: "/showroom/BohoChic/GAGA TOP.jpg",
    description: "Perfekte GAGA-KOMBINATION",
    material: "Material 100% Polyester \n (Satin-Polyester, leicht dehnbar)",
    size: "Einheitsgröße / Übergröße",
    collection: "Oversize Dresses + Boho Chic",
  },
  {
    id: 10,
    name: "NORAH",
    src: "/showroom/BohoChic/NORAH.jpg",
    description:
      "Dieses schöne Modell in A-Form, wirkt durch das leicht glänzende Material, und den Federboa Besatz elegant und glamouroes, und hat durchaus Party Potential. Die tolle Passform umspielt die Figur auf eine schöne Art, und sorgt für kreativen Schwung.",
    material: "Material 100% Polyester \n Besatz Truthahn Federboa schwarz",
    size: "Einheitsgroesse / Uebergroesse",
    collection: "Oversize Dresses + Boho Chic",
  },
  {
    id: 11,
    name: "ZOE",
    src: "/showroom/BohoChic/ZOE.jpg",
    description:
      "Gerader lockerer Schnitt im aktuellen Modestil, mit Strickkragen am Hals und an den Ärmeln. Im wunderschönen schwarz-grauen Tigerprint und mit einem langen passenden Schal ist das Modell ein cooles Outfit und sehr figurfreundlich.",
    material: "Material 100% Viskose \n Strick Bund 100% Baumwolle",
    size: "Einheitsgröße / Übergröße",
    collection: "Oversize Dresses + Boho Chic",
  },
  {
    id: 12,
    name: "ISA",
    src: "/showroom/In Love with Black & White/ISA.jpg",
    description:
      "Ein Traum in weiss. Mit diesem schwingenden Kleid sind Sie auf Sommerkurs, und leger wie elegant sorgen Sie für einen Wow Auftritt. Ein verspielter Mix mit dem langen Schal, hier als Schleife gebunden, gibt noch das gewisse Etwas. ",
    material: "Material: 100% Baumwolle",
    size: "Einheitsgröße / Übergröße",
    collection: "In Love with Black & White",
  },
  {
    id: 13,
    name: "FRIDA",
    src: "/showroom/In Love with Black & White/FRIDA.jpg",
    description:
      "Modern, frisch, elegant und unwiderstehlich ist dieses attraktive schwarz-weiße Patchwork-Kleid. Einfach, aber nicht schlicht, wird mit diesem figurschmeichelnden Outfit auch eine weibliche Figur in Szene gesetzt.",
    material: "Material: 100% Baumwolle",
    size: "Einheitsgröße / Übergröße",
    collection: "In Love with Black & White",
  },
  {
    id: 14,
    name: "CHER",
    src: "/showroom/In Love with Black & White/CHER.jpg",
    description:
      "Cooles stylisches Tunika Kleid im schwarz-weissen Patchwork look. Der raffinierte gradlinige  Schnitt steht für Zeitlosigkeit und Eleganz, und umspielt vorteilhaft, kaschierend und lässig die Figur. Einfach chic diese moderne schlichte Eleganz.",
    material: "Material: 100% Baumwolle",
    size: "Einheitsgröße / Übergröße",
    collection: "In Love with Black & White",
  },
  {
    id: 15,
    name: "CHER SET",
    src: "/showroom/In Love with Black & White/CHER SET.jpg",
    description:
      "Ein Blickfang. \n Mit dem langen Schal in Schwarz-Weiß als Highlight, peppt er das Outfit zu etwas Besonderem auf.",
    material:
      "Material:  Kleid 100% Baumwolle \n Material: Schal 97% Baumwolle 3% Elastan",
    size: "Einheitsgröße / Übergröße",
    collection: "In Love with Black & White",
  },
  {
    id: 16,
    name: "CARLA",
    src: "/showroom/In Love with Black & White/CARLA.jpg",
    description:
      "Gegensätze ziehen sich an! \n Diese lässige Jacke aus festerem Material, mit weiten Ärmeln und einem schwarz-weißen charismatischen Streifendesign, sorgt für einen abwechslungsreichen Look. In Kombination mit einem schwarzen oder weißen Kleidungsstück, individuell & cool.",
    material: "Material: Jacke 97 % Baumwolle 3% Elastan",
    size: "Einheitsgröße / Übergröße",
    collection: "In Love with Black & White",
  },
  {
    id: 17,
    name: "CARLA LANG",
    src: "/showroom/In Love with Black & White/CARLA LANG.jpg",
    description: "Elegante Modernität und Ausstrahlung",
    material: "Material: 97 % Baumwolle 3% Elastan",
    size: "Einheitsgröße / Übergröße",
    collection: "In Love with Black & White",
  },
  {
    id: 18,
    name: "CARLA SET MANTEL LANG",
    src: "/showroom/In Love with Black & White/CARLA SET COAT LONG.jpg",
    description:
      "In dieser figurbetonten Variante, mit dem langen, im oberen Bereich gebundenen Schal, ist dieses Modell fast schon ein figurformender Zaubermantel, aber auch eine trendige, individuelle Alternative und für eine eher große Frau zu empfehlen.",
    material: "Material 97% Baumwolle 3% Elastan",
    size: "Einheitsgröße / Übergröße",
    collection: "In Love with Black & White",
  },
  {
    id: 19,
    name: "ROMY",
    src: "/showroom/In Love with Black & White/ROMY.jpg",
    description:
      "Die klassische Schönheit. Der schwungvoll schöne weite und bequeme Schnitt, sowie das weiche weiße Baumwollmaterial vermitteln ein luftiges Gefühl. Da ist Sommer drin! Absolut figurfreundlich spricht das Modell eine breite Palette moderner Frauen an.",
    material: "Material: 100% Baumwolle",
    size: "Einheitsgröße / Übergröße",
    collection: "In Love with Black & White",
  },
  {
    id: 20,
    name: "CORA",
    src: "/showroom/In Love with Black & White/CORA.jpg",
    description:
      "Wunderschönes edles avantgardistisches leichtes Leinenkleid, in charismatischer schwarz-weißer Patchwork-Optik, dieses Modell ist etwas Besonderes. Sanft und lässig schmeichelt es der Figur auf ansprechende Weise und setzt jede Frau in Szene. \n Ein Kleid zum Verlieben.",
    material: "Material: 100% Leinen",
    size: "Einheitsgröße / Übergröße",
    collection: "In Love with Black & White",
  },
  {
    id: 21,
    name: "CORA SET",
    src: "/showroom/In Love with Black & White/CORA SET.jpg",
    description:
      "Lieben Sie das Besondere? \n Stilvoll und extravagant ist das Modell CORA mit schwarz-weißem Leinenschal aus dem gleichen Material, einfach ein Hingucker.",
    material: "Material: Schal 100% Leinen \n Material: Kleid 100% Leinen",
    size: "Einheitsgröße / Übergröße",
    collection: "In Love with Black & White",
  },
  {
    id: 22,
    name: "CINDY",
    src: "/showroom/In Love with Black & White/CINDY.jpg",
    description:
      "Cooles Tunikakleid, sanft, elegant, lässig und schmeichelnd im Schnitt. Mit der feinen Streifenstruktur in cremigem Weiß-Schwarz und mit dem weichen Material ist dieses Kleid ein Outfit für alle Jahreszeiten.",
    material: "Material: 95% Polyester 5% Elastan",
    size: "Einheitsgröße / Übergröße",
    collection: "In Love with Black & White",
  },
  {
    id: 23,
    name: "NANCY",
    src: "/showroom/In Love with Black & White/NANCY.jpg",
    description:
      "Ein modernes Outfit und einen frischen Look vermittelt dieses Kleid in einem weiten Schnitt. Das weiße Baumwollmaterial vermittelt das Gefühl von Leichtigkeit und Frische, und die perfekte Passform kaschiert schmeichelhaft Problemzonen. Perfekt für jedes Alter! Das macht Lust auf Sommer.",
    material: "Material: 100% Baumwolle",
    size: "Einheitsgröße / Übergröße",
    collection: "In Love with Black & White",
  },
  {
    id: 24,
    name: "NANCY SET SCHAL KURZ",
    src: "/showroom/In Love with Black & White/NANCY SET SCARF SHORT.jpg",
    description: "Ein frischer, frecher Look.",
    material:
      "Material Schal 97 % Baumwolle 3% Elastan \n Material Kleid 100% Baumwolle",
    size: "Einheitsgröße / Übergröße",
    collection: "In Love with Black & White",
  },
  {
    id: 25,
    name: "NANCY SET SCHAL LANG",
    src: "/showroom/In Love with Black & White/NANCY SET SCARF LONG.jpg",
    description:
      "Ein optischer Blickfang. Dieses Modell ist offen für jeden Spaß.",
    material:
      "Material Schal 100% Baumwolle 3% Elastan \n Material Kleid 100% Baumwolle",
    size: "Einheitsgröße / Übergröße",
    collection: "In Love with Black & White",
  },
  {
    id: 26,
    name: "NANCY SET MIT SCHAL LANG SCHWARZ",
    src: "/showroom/In Love with Black & White/NANCY SET with SCARF LONG BLACK.jpg",
    description: "",
    material: "Material: Schal 100% Baumwolle \n material Kleid 100% Baumwolle",
    size: "Einheitsgröße / Übergröße",
    collection: "In Love with Black & White",
  },
  {
    id: 27,
    name: "UTA",
    src: "/showroom/In Love with Black & White/UTA.jpg",
    description:
      "Klassisch zeitloser, figurbetonter Schnitt, sinnlich und sexy, und das weiche, anschmiegsame Material sorgt für hohen Tragekomfort.",
    material: "Material: 92% nylon 8% elastan",
    size: "Einheitsgröße / Übergröße",
    collection: "In Love with Black & White",
  },
  {
    id: 28,
    name: "UTA SET mit Top",
    src: "/showroom/In Love with Black & White/UTA SET WITH TOP.jpg",
    description:
      "Ein schönes Spiel mit Asymmetrien verwandelt das figurbetonte UTA-Kleid in ein figurformendes, elegantes Outfit. Wunderbar kaschierend durch das asymmetrische Oberteil, passt dieses attraktive Outfit zu fast jeder Frau in jedem Alter, und auch zu vielen Anlässen tragbar.",
    material: "Material: 92% nylon 8% elastan",
    size: "Einheitsgröße / Übergröße",
    collection: "In Love with Black & White",
  },
  {
    id: 29,
    name: "LENA",
    src: "/showroom/In Love with Black & White/LENA.jpg",
    description:
      "Asymmetrisches Oberteil mit locker-leichter und fließender Passform, sorgt für eine schicke und lässige Note in Kombination mit vielen Kleidungsstücken.",
    material: "Material: 92% nylon 8% elastan",
    size: "Einheitsgröße / Übergröße",
    collection: "In Love with Black & White",
  },
  {
    id: 30,
    name: "ANN",
    src: "/showroom/In Love with Black & White/ANN.jpg",
    description:
      "Hemdkleid mit geradem, weitem, leicht übergroßem Schnitt, in fließender Silhouette mit Seitenschlitzen, vermittelt sommerliche Fröhlichkeit.",
    material: "Material: 100% Baumwolle",
    size: "Einheitsgröße / Übergröße",
    collection: "In Love with Black & White",
  },
  {
    id: 31,
    name: "ANNA",
    src: "/showroom/In Love with Black & White/ANNA.jpg",
    description:
      "Hemdblusenkleid in Schwarz, mit geradem, weitem, leichtem Oversized-Schnitt, in fließender Silhouette und mit Seitenschlitzen, zeigt Eleganz und Leichtigkeit zugleich.",
    material: "Material: 100% Baumwolle",
    size: "Einheitsgröße / Übergröße",
    collection: "In Love with Black & White",
  },
  {
    id: 32,
    name: "ANNA SET SCHAL KURZ",
    src: "/showroom/In Love with Black & White/ANNA SET SCARF SHORT.jpg",
    description: "",
    material: "Schal material 97% Baumwolle 3% Elastan \n Kleid 100% Baumwolle",
    size: "Einheitsgröße / Übergröße",
    collection: "In Love with Black & White",
  },
  {
    id: 33,
    name: "CAMILLE SET",
    src: "/showroom/In Love with Black & White/CAMILLE SET.jpg",
    description:
      "dieses Set, weißes Maxikleid, trendig, schlicht, ärmellos, in Kombination mit diesem Top in schwarz-weiß, ist stilvoll, individuell, kaschierend und ein cooles Outfit. Besonders empfehlenswert für eine große Frau.",
    material: "Material: dress & top 100% Baumwolle",
    size: "Einheitsgröße / Übergröße",
    collection: "In Love with Black & White",
  },
  {
    id: 34,
    name: "RONJA",
    src: "/showroom/In Love with Black & White/RONJA.jpg",
    description:
      "Dieses schwarze Baumwollkleid in weiter A-Linie wirkt zeitlos schlicht und elegant, aber je nach Wahl der Accessoires ist die Wirkung immer anders.",
    material: "Material: 100% Baumwolle",
    size: "Einheitsgröße / Übergröße",
    collection: "In Love with Black & White",
  },
  {
    id: 35,
    name: "LOUISE",
    src: "/showroom/In Love with Black & White/LOUISE.jpg",
    description:
      "Schick, cool und leicht oversized, aus schwarzem Leinen, mit einem lässigen, klaren, klassischen Schnitt, modern, elegant und vielseitig.",
    material: "Material: 100% Leinen",
    size: "Einheitsgröße / Übergröße",
    collection: "In Love with Black & White",
  },
  {
    id: 36,
    name: "LOUISE in Kombination mit Kleid ANN",
    src: "/showroom/In Love with Black & White/LOUISE 2.jpg",
    description: "In Kombination mit Kleid ANN",
    material: "Material: 100% Leinen",
    size: "Einheitsgröße / Übergröße",
    collection: "In Love with Black & White",
  },
  {
    id: 37,
    name: "MANDY",
    src: "/showroom/Happy Hippie Hop/MANDY.jpg",
    description:
      "erstrahlt in zarten, fröhlichen Pastellfarben und im Patchwork-Mix. Der leicht figurbetonte Schnitt mit kreativem Schwung, im leichten Baumwollmaterial, ist figurfreundlich und bequem und lässt Sie lässig und individuell wirken.",
    material: "Material: 100% Baumwolle",
    size: "Einheitsgröße / Übergröße",
    collection: "Happy Hippie Hop",
  },
  {
    id: 38,
    name: "ZURI",
    src: "/showroom/Happy Hippie Hop/ZURI.jpg",
    description:
      "Frisches, freches Ballonkleid, leicht oversized mit dezenter grau-weißer Streifenstruktur, besticht durch seine schlichte Schönheit, leicht figurbetont und schnörkellos.",
    material: "Material: 60% Baumwolle 40% Polyester",
    size: "Einheitsgröße / Übergröße",
    collection: "Happy Hippie Hop",
  },
  {
    id: 39,
    name: "GINA",
    src: "/showroom/Happy Hippie Hop/GINA.jpg",
    description:
      "Ein endless summer dress. Im strahlenden und frischen gruen, chic und vitalisierend. Das schlichte oversized Maxikleid hat eine tolle Passform, alltagstauglich, oder auch ein schönes Event, das lockere Kleid geht immer.",
    material: "Material: 100% Baumwolle",
    size: "Einheitsgröße / Übergröße",
    collection: "Happy Hippie Hop",
  },
  {
    id: 42,
    name: "ELISA",
    src: "/showroom/Happy Hippie Hop/ELISA.jpg",
    description:
      "Mit einem angesagten Look und dem charismatischen Trenddesign, ist dieses leicht körperbetonte Maxidress, aus weichem Material, wunderbar zu tragen. Ganz und gar nicht langweilig, mit diesem Kleid gehen Sie direkt in den Club.",
    material: "Material: 100% Polyester",
    size: "Einheitsgröße / Übergröße",
    collection: "Happy Hippie Hop",
  },
  {
    id: 43,
    name: "ELISA SET",
    src: "/showroom/Happy Hippie Hop/ELISASET.jpg",
    description:
      "Dieser Look mit dem asymmetrischen Top hat die richtige Kombination, und eine weibliche Figur verleiht diesem Outfit ebenfalls einen Wow-Effekt.",
    material: "Material: 100% Polyester",
    size: "Einheitsgröße / Übergröße",
    collection: "Happy Hippie Hop",
  },
  {
    id: 44,
    name: "ELISA TOP",
    src: "/showroom/Happy Hippie Hop/ELISATOP.jpg",
    description: "Den Kombinationsmöglichkeiten sind keine Grenzen gesetzt.",
    material: "Material: 100% Polyester",
    size: "Einheitsgröße / Übergröße",
    collection: "Happy Hippie Hop",
  },
  {
    id: 45,
    name: "FENJA",
    src: "/showroom/Happy Hippie Hop/FENJA.jpg",
    description:
      "Fröhlich, modern, frisch und frech mit einem leicht körpernahen Schnitt ist dieses Kleid aus weichem, anschmiegsamem Material einfach zum Verlieben.",
    material: "Material: 100% Polyester",
    size: "Einheitsgröße / Übergröße",
    collection: "Happy Hippie Hop",
  },
  {
    id: 46,
    name: "FENJA SET",
    src: "/showroom/Happy Hippie Hop/FENJASET.jpg",
    description:
      "Locker kaschierend mit dem asymmetrischen Top eine schöne Kombination, perfekt für jedes Alter und jeden Anlass.",
    material: "Material: 100% Polyester",
    size: "Einheitsgröße / Übergröße",
    collection: "Happy Hippie Hop",
  },
  {
    id: 47,
    name: "FENJA TOP",
    src: "/showroom/Happy Hippie Hop/FENJATOP.jpg",
    description:
      "Dieses Top ist ein echtes Kombinationswunder, sieht gut aus über einem Kleid, Jeans, Leggins oder Hosen.",
    material: "Material: 100% Polyester",
    size: "Einheitsgröße / Übergröße",
    collection: "Happy Hippie Hop",
  },
  {
    id: 48,
    name: "ELLA",
    src: "/showroom/Happy Hippie Hop/ELLA.JPG",
    description:
      "Feminines und minimalistisches Design geben bei diesem Tunikakleid den Ton an. \n Dieses Kleidungsstück ist elegant, stilvoll, figurfreundlich und hat einen modernen Schick.",
    material: "Material: 60% Baumwolle 40% Polyester",
    size: "Einheitsgröße / Übergröße",
    collection: "Happy Hippie Hop",
  },
  {
    id: 49,
    name: "SUZIE",
    src: "/showroom/Happy Hippie Hop/SUZIE.jpg",
    description:
      "Das luftige Ballonkleid vermittelt ein Gefühl von Leichtigkeit und Schick, ein modernes und bequemes Outfit, und der vorteilhafte, figurschmeichelnde Schnitt dürfte eine Vielzahl moderner Frauen ansprechen.",
    material: "Material: 60% Baumwolle 40% Polyester",
    size: "Einheitsgröße / Übergröße",
    collection: "Happy Hippie Hop",
  },
  {
    id: 50,
    name: "MONA",
    src: "/showroom/Happy Hippie Hop/MONA.jpg",
    description:
      "Ballonkleid in eleganter grau-grüner Nadelstreifenoptik. Zeitlos lässig und bequem passt dieses Kleid fast jeder Frau.",
    material: "Material: 60% Baumwolle 40% Polyester",
    size: "Einheitsgröße / Übergröße",
    collection: "Happy Hippie Hop",
  },
  {
    id: 51,
    name: "XENIA",
    src: "/showroom/Happy Hippie Hop/XENIA.jpg",
    description:
      "Schicke Tunika mit gerader figurbetonter Passform, klassisch und zeitlos schön, mit moderner schlichter Eleganz. Bequem mit einem seitlichen Reißverschluss für ausreichend Bewegung.",
    material: "Material: 60% Baumwolle 40% Polyester",
    size: "Einheitsgröße / Übergröße",
    collection: "Happy Hippie Hop",
  },
  {
    id: 52,
    name: "PIXIE",
    src: "/showroom/Happy Hippie Hop/PIXIE.jpg",
    description:
      "Tunikakleid mit schöner Silhouette im Patchwork-Mix wirkt interessant, frisch und bunt. Das Kleid sitzt locker und figurfreundlich.",
    material: "Material: 100% Baumwolle",
    size: "Einheitsgröße / Übergröße",
    collection: "Happy Hippie Hop",
  },
  {
    id: 53,
    name: "VICKY SET MIT JACKE",
    src: "/showroom/Happy Hippie Hop/VICKYSETWJACKET.jpg",
    description:
      "Pastellfarbenes Maxikleid mit Jacke, verleiht Lässigkeit und Eleganz zugleich, durch die ausgestellte weite Passform werden Problemzonen wunderbar kaschiert und neben der Lässigkeit sorgt dieses Modell auch an kühleren Tagen für ein Rundum-Wohlgefühl.",
    material: "Material: 50% Baumwolle 50% Polyacryl",
    size: "Einheitsgröße / Übergröße",
    collection: "Happy Hippie Hop",
  },
  {
    id: 54,
    name: "VICKY SET with TOP",
    src: "/showroom/Happy Hippie Hop/VICKY SETWTOP.jpg",
    description: "und die Wirkung ist wieder anders.",
    material: "Material: 50% Baumwolle 50% Polyacryl",
    size: "Einheitsgröße / Übergröße",
    collection: "Happy Hippie Hop",
  },
  {
    id: 55,
    name: "MIRJA",
    src: "/showroom/Happy Hippie Hop/MIRJA.jpg",
    description:
      "Buntes Patchwork-Kleid, leicht und beschwingt schmeichelt es der Figur, und der schöne Farbmix vermittelt eine sonnig-leichte Unbeschwertheit.",
    material: "Material: 100% Baumwolle",
    size: "Einheitsgröße / Übergröße",
    collection: "Happy Hippie Hop",
  },
  {
    id: 56,
    name: "EVI",
    src: "/showroom/Happy Hippie Hop/EVI.jpg",
    description:
      "Frisches kurzes Sommerkleid mit einer Raffung in der Taille aerea.",
    material: "Material: 100% Baumwolle",
    size: "Einheitsgröße / Übergröße",
    collection: "Happy Hippie Hop",
  },
  {
    id: 57,
    name: "LOLA",
    src: "/showroom/Happy Hippie Hop/LOLA.jpg",
    description:
      "Elegantes, cooles Kleid im Tunika-Stil und in leichter Übergröße, besticht durch ein raffendes Knotendetail, dezentes Streifendesign und den lässigen, figurumspielenden Schnitt in weichem Material.",
    material: "Material: 60% Baumwolle 40% Polyester",
    size: "Einheitsgröße / Übergröße",
    collection: "Happy Hippie Hop",
  },
  {
    id: 58,
    name: "COCO",
    src: "/showroom/Happy Hippie Hop/COCO.jpg",
    description:
      "Extravagant cooles Tunikakleid, aus reiner Seide, wirkt durch das frech fließende bunte Design und die leicht oversized Passform. \n Einfach kokett und raffiniert. \n Es ist Sommerzeit!",
    material: "Material: Reine 100% Seide",
    size: "Einheitsgröße / Übergröße",
    collection: "Happy Hippie Hop",
  },
  {
    id: 59,
    name: "STELLA",
    src: "/showroom/Happy Hippie Hop/STELLA.jpg",
    description:
      "Schicke Avantgarde-Jacke aus reiner Seide, leichter gerader Oversized-Schnitt sorgt für eine lässige Note, perfekte Ergänzung zum Kleid COCO, aber auch extravagant und facettenreich solo getragen. \n Frisch & frei ist die Devise!",
    material: "Material: Reine 100% Seide",
    size: "Einheitsgröße / Übergröße",
    collection: "Happy Hippie Hop",
  },
  {
    id: 40,
    name: "KIM",
    src: "/showroom/Happy Hippie Hop/KIM.jpg",
    description:
      "Wunderschöne hochwertige weiche Seide, der klassische Stil, das moderne charismatische farbige Design, der gerade weite Schnitt und der zu einer Schleife gebundene Schal, machen dieses Kleid zu einem optischen Highlight.",
    material: "Material: Reine 100% Seide",
    size: "Einheitsgröße / Übergröße",
    collection: "Happy Hippie Hop",
  },
  {
    id: 60,
    name: "AMY",
    src: "/showroom/Kaftans/AMY 1.jpg",
    description:
      "Mit dem feinen, seidigen Material mit elegantem Glanz, dem schicken  Design, und dem fliessenden lockeren Schnitt, der die Figur sanft  umschmeichelt, passt dieser Kaftan zu vielen verschiedenen Koerperformen. Mit cooler Eleganz  haben Sie mit diesem schönen Outfit einen stilvollen Auftritt.",
    material: "Material: 100% Polyester (Seidensatin)",
    size: "Einheitsgröße / Übergröße",
    collection: "Kaftan Avant Garde Dresses",
  },
  {
    id: 61,
    name: "BEA",
    src: "/showroom/Kaftans/BEA 2.jpg",
    description:
      "Mit dem feinen, seidigen Material mit elegantem Glanz, dem schicken  Design, und dem fliessenden lockeren Schnitt, der die Figur sanft  umschmeichelt, passt dieser Kaftan zu vielen verschiedenen Koerperformen. Mit cooler Eleganz  haben Sie mit diesem schönen Outfit einen stilvollen Auftritt.",
    material: "Material: 100% Polyester (Seidensatin)",
    size: "Einheitsgröße / Übergröße",
    collection: "Kaftan Avant Garde Dresses",
  },
  {
    id: 62,
    name: "CELINA",
    src: "/showroom/Kaftans/CELINA 3.jpg",
    description:
      "Mit dem feinen, seidigen Material mit elegantem Glanz, dem schicken  Design, und dem fliessenden lockeren Schnitt, der die Figur sanft  umschmeichelt, passt dieser Kaftan zu vielen verschiedenen Koerperformen. Mit cooler Eleganz  haben Sie mit diesem schönen Outfit einen stilvollen Auftritt.",
    material: "Material: 100% Polyester (Seidensatin)",
    size: "Einheitsgröße / Übergröße",
    collection: "Kaftan Avant Garde Dresses",
  },
  {
    id: 63,
    name: "DANA",
    src: "/showroom/Kaftans/DANA 4.jpg",
    description:
      "Mit dem feinen, seidigen Material mit elegantem Glanz, dem schicken  Design, und dem fliessenden lockeren Schnitt, der die Figur sanft  umschmeichelt, passt dieser Kaftan zu vielen verschiedenen Koerperformen. Mit cooler Eleganz  haben Sie mit diesem schönen Outfit einen stilvollen Auftritt.",
    material: "Material: 100% Polyester (Seidensatin)",
    size: "Einheitsgröße / Übergröße",
    collection: "Kaftan Avant Garde Dresses",
  },
  {
    id: 64,
    name: "EDDA",
    src: "/showroom/Kaftans/EDDA 5.jpg",
    description:
      "Mit dem feinen, seidigen Material mit elegantem Glanz, dem schicken  Design, und dem fliessenden lockeren Schnitt, der die Figur sanft  umschmeichelt, passt dieser Kaftan zu vielen verschiedenen Koerperformen. Mit cooler Eleganz  haben Sie mit diesem schönen Outfit einen stilvollen Auftritt.",
    material: "Material: 100% Polyester (Seidensatin)",
    size: "Einheitsgröße / Übergröße",
    collection: "Kaftan Avant Garde Dresses",
  },
  {
    id: 65,
    name: "FREYA",
    src: "/showroom/Kaftans/FREYA 6.jpg",
    description:
      "Mit dem feinen, seidigen Material mit elegantem Glanz, dem schicken  Design, und dem fliessenden lockeren Schnitt, der die Figur sanft  umschmeichelt, passt dieser Kaftan zu vielen verschiedenen Koerperformen. Mit cooler Eleganz  haben Sie mit diesem schönen Outfit einen stilvollen Auftritt.",
    material: "Material: 100% Polyester (Seidensatin)",
    size: "Einheitsgröße / Übergröße",
    collection: "Kaftan Avant Garde Dresses",
  },
  {
    id: 66,
    name: "LIA",
    src: "/showroom/Kaftans/LIA 7.jpg",
    description:
      "Mit dem feinen, seidigen Material mit elegantem Glanz, dem schicken  Design, und dem fliessenden lockeren Schnitt, der die Figur sanft  umschmeichelt, passt dieser Kaftan zu vielen verschiedenen Koerperformen. Mit cooler Eleganz  haben Sie mit diesem schönen Outfit einen stilvollen Auftritt.",
    material: "Material: 100% Polyester (Seidensatin)",
    size: "Einheitsgröße / Übergröße",
    collection: "Kaftan Avant Garde Dresses",
  },
  {
    id: 67,
    name: "MILA",
    src: "/showroom/Kaftans/MILA 8.jpg",
    description:
      "Mit dem feinen, seidigen Material mit elegantem Glanz, dem schicken  Design, und dem fliessenden lockeren Schnitt, der die Figur sanft  umschmeichelt, passt dieser Kaftan zu vielen verschiedenen Koerperformen. Mit cooler Eleganz  haben Sie mit diesem schönen Outfit einen stilvollen Auftritt.",
    material: "Material: 100% Polyester (Seidensatin)",
    size: "Einheitsgröße / Übergröße",
    collection: "Kaftan Avant Garde Dresses",
  },
  {
    id: 68,
    name: "NOVA",
    src: "/showroom/Kaftans/NOVA 9.jpg",
    description:
      "Mit dem feinen, seidigen Material mit elegantem Glanz, dem schicken  Design, und dem fliessenden lockeren Schnitt, der die Figur sanft  umschmeichelt, passt dieser Kaftan zu vielen verschiedenen Koerperformen. Mit cooler Eleganz  haben Sie mit diesem schönen Outfit einen stilvollen Auftritt.",
    material: "Material: 100% Polyester (Seidensatin)",
    size: "Einheitsgröße / Übergröße",
    collection: "Kaftan Avant Garde Dresses",
  },
  {
    id: 69,
    name: "MARA",
    src: "/showroom/Kaftans/MARA 10.jpg",
    description:
      "Mit dem feinen, seidigen Material mit elegantem Glanz, dem schicken  Design, und dem fliessenden lockeren Schnitt, der die Figur sanft  umschmeichelt, passt dieser Kaftan zu vielen verschiedenen Koerperformen. Mit cooler Eleganz  haben Sie mit diesem schönen Outfit einen stilvollen Auftritt.",
    material: "Material: 100% Polyester (Seidensatin)",
    size: "Einheitsgröße / Übergröße",
    collection: "Kaftan Avant Garde Dresses",
  },
  {
    id: 70,
    name: "PIA",
    src: "/showroom/Kaftans/PIA 11.jpg",
    description:
      "Mit dem feinen, seidigen Material mit elegantem Glanz, dem schicken  Design, und dem fliessenden lockeren Schnitt, der die Figur sanft  umschmeichelt, passt dieser Kaftan zu vielen verschiedenen Koerperformen. Mit cooler Eleganz  haben Sie mit diesem schönen Outfit einen stilvollen Auftritt.",
    material: "Material: 100% Polyester (Seidensatin)",
    size: "Einheitsgröße / Übergröße",
    collection: "Kaftan Avant Garde Dresses",
  },
  {
    id: 71,
    name: "NIKA",
    src: "/showroom/Kaftans/NIKA 12.jpg",
    description:
      "Mit dem feinen, seidigen Material mit elegantem Glanz, dem schicken  Design, und dem fliessenden lockeren Schnitt, der die Figur sanft  umschmeichelt, passt dieser Kaftan zu vielen verschiedenen Koerperformen. Mit cooler Eleganz  haben Sie mit diesem schönen Outfit einen stilvollen Auftritt.",
    material: "Material: 100% Polyester (Seidensatin)",
    size: "Einheitsgröße / Übergröße",
    collection: "Kaftan Avant Garde Dresses",
  },
  {
    id: 72,
    name: "MILOU",
    src: "/showroom/Kaftans/MILOU 13.jpg",
    description:
      "Mit dem feinen, seidigen Material mit elegantem Glanz, dem schicken  Design, und dem fliessenden lockeren Schnitt, der die Figur sanft  umschmeichelt, passt dieser Kaftan zu vielen verschiedenen Koerperformen. Mit cooler Eleganz  haben Sie mit diesem schönen Outfit einen stilvollen Auftritt.",
    material: "Material: 100% Polyester (Seidensatin)",
    size: "Einheitsgröße / Übergröße",
    collection: "Kaftan Avant Garde Dresses",
  },
  {
    id: 73,
    name: "CIAIRE",
    src: "/showroom/Kaftans/CIAIRE 14.jpg",
    description:
      "Mit dem feinen, seidigen Material mit elegantem Glanz, dem schicken  Design, und dem fliessenden lockeren Schnitt, der die Figur sanft  umschmeichelt, passt dieser Kaftan zu vielen verschiedenen Koerperformen. Mit cooler Eleganz  haben Sie mit diesem schönen Outfit einen stilvollen Auftritt.",
    material: "Material: 100% Polyester (Seidensatin)",
    size: "Einheitsgröße / Übergröße",
    collection: "Kaftan Avant Garde Dresses",
  },
  {
    id: 74,
    name: "MONA",
    src: "/showroom/Kaftans/MONA 15.jpg",
    description:
      "Mit dem feinen, seidigen Material mit elegantem Glanz, dem schicken  Design, und dem fliessenden lockeren Schnitt, der die Figur sanft  umschmeichelt, passt dieser Kaftan zu vielen verschiedenen Koerperformen. Mit cooler Eleganz  haben Sie mit diesem schönen Outfit einen stilvollen Auftritt.",
    material: "Material: 100% Polyester (Seidensatin)",
    size: "Einheitsgröße / Übergröße",
    collection: "Kaftan Avant Garde Dresses",
  },
];

export default allProductsGerman;
