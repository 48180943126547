import React from 'react';
import './Testimonials.css';

const Testimonials = () => {
  return (
    <div className="testimonials" id="testimonials">
      <div className="container">
        <div className="text">
          <p>Atelier Ingrid Fuerbass </p>
          {/* <p>Phone: 0034 641 28 47 94</p> */}
          <p>E-Mail: info@fashionmonks.com</p>
          <p>Carrer Rectoria 12</p>
          <p>07520 Petra (Mallorca)</p>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
