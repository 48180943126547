import React from 'react'
import './Demo.css'

import allProducts from "../services/watchesEnglish";
import allProductsGerman from "../services/watchesGerman";
import ProductCard from "./ProductCarousel/ProductCard"

const Demo = (props) => {
    return (
        <div className='demo' id='demo'>
            <div className='container'>
                    {props.language === 0 ?
                        <ProductCard slides={allProducts} language={props.language} />
                        :
                        <ProductCard slides={allProductsGerman} language={props.language}/>
                    }
            </div>
        </div>
    )
}

export default Demo
